const convertToFormData = (data, formData = new FormData(), parentKey = '') => {
    if (data && typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        const value = data[key];
        const newKey = parentKey ? `${parentKey}[${key}]` : key;
  
        if (value instanceof File) {
          // إذا كانت القيمة ملفًا (File)، أضفها مباشرة إلى FormData
          formData.append(newKey, value);
        } else if (Array.isArray(value) && value.length !== 0) {
          // إذا كانت المصفوفة غير فارغة، تعامل مع كل عنصر فيها
          value.forEach((item, index) => {
            const arrayKey = `${newKey}[${index}]`;
            convertToFormData(item, formData, arrayKey);
          });
        } else if (typeof value === 'object' && value !== null) {
          // إذا كانت القيمة كائنًا متداخلًا، تعامل معه بشكل متكرر
          convertToFormData(value, formData, newKey);
        } else {
          // إذا كانت القيمة قيمة بسيطة (string, number, boolean)، أضفها إلى FormData
          formData.append(newKey, value);
        }
      });
    } else {
      // إذا كانت البيانات قيمة بسيطة (string, number, boolean)، أضفها إلى FormData
      formData.append(parentKey, data);
    }
  
    return formData;
  };

  export default convertToFormData;