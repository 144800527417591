import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import toLocalTimezone from "../../../../../Services/Timezone";
import { ReactComponent as ExamIcon } from "../../../../../assets/icons/ExamIcon.svg";
import LessonItem from "./LessonItem";

export default function LessonQuizItem({
  courseId,
  chapterId,
  unitId,
  lessonId,
  quiz,
  onOpen,
  isCourseClosed,
  selfPaced,
  role,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const params = {
    icon: <ExamIcon className="tw-h-5" />,
    badge: {
      label: t("trainer.course.lesson.exams"),
      color: "#3db9be",
    },
    title: quiz.title,
    // description: quiz.monitored ? `(${t("monitored")})` : `( ${t("questions_length")} ${quiz.questions_count})`,
    columns: [
      {
        label: t("answer_attempts"),
        value: `${quiz.my_answered_count}/${quiz.answer_attempts}`,
      },
      {
        label: t("passPercent"),
        value: `${Math.round(parseFloat(quiz.success_rate) * 100) / 100}%`,
      },
      {
        label: t("quiz_grade"),
        value: `${Math.round(parseFloat(quiz.total_grade) * 100) / 100}`,
      },
    ],
    buttons: [
      {
        label: t("participate"),
        disabled:
          quiz.my_answered_count >= quiz.answer_attempts ||
          ((new Date(toLocalTimezone(quiz.start_date)) > new Date() ||
            new Date(toLocalTimezone(quiz.end_date)) < new Date()) &&
            !selfPaced) ||
          isCourseClosed,
        onClick: () => {
          onOpen();
          history.push(
            `/${
              role ? role : "trainees"
            }/course/${courseId}/chapter/${chapterId}/exam/${quiz.id}`
          );
        },
        secondary: true,
      },
      {
        label: t("results"),
        disabled:
          quiz.my_answered_count <= 0 ||
          !quiz.show_mark ||
          (quiz.show_mark_condition === 1 &&
            new Date(quiz.show_mark_condition_date) > new Date()),
        onClick: () => {
          history.push(
            `/trainees/course/${courseId}/chapter/${chapterId}/exam/${quiz.id}/result`
          );
        },
      },
    ],
    footer: {
      flags: {
        include_view: quiz.include_view_per,
        include_achivement: quiz.include_achivement_per,
        finished:
          quiz.my_answered_count >= quiz.answer_attempts ||
          (new Date(toLocalTimezone(quiz.end_date)) < new Date() && !selfPaced),
        is_watched: quiz.is_watched,
        is_achieved: quiz.is_achieved && quiz.is_answers_corrected,
      },
      columns:
        quiz.my_answered_count > 0
          ? [
              {
                label: t("time_for"),
                value: `${quiz.period} ${t("min")}`,
              },
              {
                label: t("quiz_grading"),
                value: quiz.is_auto_corrected
                  ? t("completed")
                  : t("not_completed"),
                color: quiz.is_auto_corrected ? "green" : "gray",
              },
              ...(selfPaced
                ? []
                : [
                    {
                      label: t("avialible_from"),
                      value: moment(toLocalTimezone(quiz.start_date)).format(
                        "YYYY-MM-DD hh:mm A"
                      ),
                      rows: [
                        {
                          label: t("To"),
                          value: moment(toLocalTimezone(quiz.end_date)).format(
                            "YYYY-MM-DD hh:mm A"
                          ),
                        },
                      ],
                    },
                  ]),
            ]
          : [
              {
                label: t("time_for"),
                value: `${quiz.period} ${t("min")}`,
              },
              // {
              //   label: t("quiz_grading"),
              //   value: quiz.is_auto_corrected
              //     ? t("completed")
              //     : t("not_completed"),
              //   color: quiz.is_auto_corrected ? "green" : "gray",
              // },
              ...(selfPaced
                ? []
                : [
                    {
                      label: t("avialible_from"),
                      value: moment(toLocalTimezone(quiz.start_date)).format(
                        "YYYY-MM-DD hh:mm A"
                      ),
                      rows: [
                        {
                          label: t("To"),
                          value: moment(toLocalTimezone(quiz.end_date)).format(
                            "YYYY-MM-DD hh:mm A"
                          ),
                        },
                      ],
                    },
                  ]),
            ],
    },
  };
  return <LessonItem {...params} />;
}
