import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";

import { deleteTypeFile, getListType } from "../../../Services/api/TypeFile/TypeFileProvider";

import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import IconTooltips from "../../../Components/tooltips/IconsTooltip";
import deleteIcon from "../../../assets/icons/delete.svg";
import HomeIcon from "../../../assets/icons/home.svg";
import editIcon from "../../../assets/icons/pen.svg";
import AddIcon from "../../../assets/icons/plus.svg";
import DeleteIcon from "../../../assets/image/alert-octagon.png";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";

function TypeFileList() {
  require("./typefile.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [listTypeFile, setListTypeFile] = useState([]);
  const [toBeDeletedTypeId, setToBeDeletedTypeId] = useState(null);
  const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] = useState(false);

  const getListTypeFile = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          setListTypeFile(res.data.data);
          setLoading(false);
          resolve();
        },
        (error) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("programs.failed_loading")}</span>);
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getListTypeFile();
  }, []);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "TypeFile_label",
      page: t("admin.label.admin_label"),
      pagePath: "/admin",
    },
    {
      id: "TypeFile",
      page: t("TypeFile.title"),
      active: true,
    },
  ];

  // useEffect(() => {
  //   if (!loading) {
  //     //Here I'm calculating the height of the head and body in order to
  //     //Affect the relative element in manner of height.
  //     //The hardcoded "40" value is for the margin between the head and the body.
  //     document.getElementById("content-section").style.height =
  //       (
  //         document.getElementById("content-body-section").offsetHeight +
  //         document.getElementById("content-header-bar").offsetHeight +
  //         40
  //       ).toString() + "px";
  //   }
  // }, [loading]);

  const deleteRow = async (id) => {
    try {
      setShowDeleteModal(false);

      setIsPerformingBackgroundOperation(true);
      await deleteTypeFile(id);
      await getListTypeFile();
      setIsPerformingBackgroundOperation(false);
    } catch (err) {
      setShowDeleteModal(false);
      setIsPerformingBackgroundOperation(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {err.response.data && err.response.data.msg ? (
            <>
              {err.response.data.msg}
              <br />
              <span style={{ fontSize: 12, fontWeight: "500" }}>- {err.response.data.errors.type}</span>
            </>
          ) : (
            "Failure in service"
          )}
        </span>
      );
    }
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [titleName, setTitleName] = useState("");

  const deleteHandler = async (id, titleName1) => {
    setShowDeleteModal(true);
    setTitleName(titleName1);
    await setToBeDeletedTypeId(id);
    // deleteModalRef.current.showModal();
  };

  const modalHandler = () => deleteModalRef.current.dismissModal();
  const deleteModalRef = useRef();

  const actionsList = [
    {
      id: "add",
      icon: <img src={AddIcon} alt="" />,
      theme: "add",
      title: t("add"),
      action: () => {
        history.push(`/admin/typefile/add`);
      },
    },
  ];
  const actionsTable = [];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "type",
      name: t("TypeFile.type"),
      key: "type",
      sortable: false,
      cell: ({ rowData }) => <span className="cell-font tw-w-36 text2lines">{rowData.type}</span>,
    },
    {
      id: "size",
      name: t("TypeFile.size"),
      key: "size",
      sortable: false,
      cell: ({ rowData }) => <span className="cell-font tw-w-36 text2lines">{rowData.max_size}</span>,
    },
    {
      id: "actions",
      name: t("TypeFile.actions"),
      key: "actions",
      sortable: false,
      cell: ({ rowData }) => <span className="cell-font tw-w-36 text2lines">{rowData.user?.name}</span>,
    },
  ];

  return (
    <>
      {/* <BasicModal ref={deleteModalRef}>
        <DeleteModal
          label={t("coupon.label.delete_aprove")}
          title={t("TypeFile.model.delete.title")}
          deleteMsg={t("TypeFile.model.delete.body")}
          refuse={modalHandler}
          accept={() => deleteRow(toBeDeletedTypeId)}
          deleteModalRef={deleteModalRef}
        />
      </BasicModal> */}

      <div className="container-fluid" id={"content-section"}>
        <div className="row" id={"content-header-bar"}>
          <div className="col-12">
            <div className="pt-5 pb-3">
              <Breadcrumb list={breadcrumbList} />
            </div>
            {loading == false ? (
              <MainBox>
                {isPerformingBackgroundOperation ? <SkeletonCardOverlay borderRadius={20} /> : null}
                {/* <div className="admin_label_card" style={{ width: "100%" }}> */}
                {/* <div className="titleAddFile">{t("TypeFile.title")}</div> */}

                <MainBoxHead title={t("TypeFile.title")} actions={actionsList} />
                {/* </div> */}
                <div
                  className="table-responsive"
                  style={{
                    width: "90%",
                  }}
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr style={{ background: "#024f92" }}>
                        <th scope="col">#</th>
                        <th scope="col">{t("TypeFile.type")}</th>
                        <th scope="col">{t("TypeFile.size")} (KB)</th>
                        <th scope="col">{t("TypeFile.actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listTypeFile.map((type) => {
                        return (
                          <tr key={type.id}>
                            <th scope="row">{type.id}</th>
                            <td>{type.type}</td>
                            <td>{type.max_size}</td>
                            <td>
                              <div className="release_icon">
                                <NavLink
                                  to={{
                                    pathname: `/admin/typefile/edit/${type.id}`,
                                    state: type,
                                  }}
                                  className=""
                                >
                                  <IconTooltips
                                    title={t("modal.coupon.buttons.edit")}
                                    content={<img src={editIcon} alt="" />}
                                  />
                                </NavLink>
                                <div>
                                  {type.deletable == 0 ? (
                                    <IconTooltips
                                      title={t("trainer.quiz.tooltip.diasbled_btn")}
                                      content={
                                        <CloseIcon
                                          sx={{
                                            cursor: "pointer",
                                            border: "2px solid #999",
                                            borderRadius: "50%",
                                            padding: "0px",
                                            color: "#999",
                                            fontSize: "22px",
                                          }}
                                        />
                                      }
                                    />
                                  ) : (
                                    <IconTooltips
                                      title={t("trainer.quiz.tooltip.delete")}
                                      content={
                                        <img
                                          style={{ cursor: "pointer" }}
                                          onClick={() => deleteHandler(type.id, type.type)}
                                          src={deleteIcon}
                                          alt=""
                                        />
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {showDeleteModal && (
                    <SharedModal
                      icon={DeleteIcon}
                      title={t("TypeFile.model.delete.body")}
                      name={titleName}
                      confirmMsg={t("Exhibition.Exhibition_delete_confirmMsg")}
                      confirmText={t("modal.common.confirm")}
                      cancelText={t("modal.common.cancel")}
                      onCancel={() => {
                        setShowDeleteModal(false);
                      }}
                      onConfirm={() => {
                        deleteRow(toBeDeletedTypeId);
                      }}
                    />
                  )}
                </div>
              </MainBox>
            ) : (
              <div
                className="mt-5"
                style={{
                  height: "65vh",
                }}
              >
                <SkeletonCard />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TypeFileList;
