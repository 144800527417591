import { Pagination } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {
  deleteLivechatReply,
  getSupportTicketReply,
  getlivechatReply,
  postLivechatReplyStatus,
  resetLivechat,
} from "../../../Redux/Actions/livechat/livechat.action";
import deleteIcon from "../../../assets/icons/delete.svg";
import HomeIcon from "../../../assets/icons/home.svg";
import editExhIcon from "../../../assets/icons/pen.svg";
import DeleteIcon from "../../../assets/icons/warning.svg";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import SwitchToggle from "../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./answersReply.module.css";
import { filterActions } from "./answersReplyTableAction";

const AnswersReply = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { livechat_reply, livechat_reply_status, livechat_delete_reply } = useSelector(
    (state) => state.livechatReducer
  );
  const [replies, setReplies] = useState([]);
  const [deletedId, setDeletedId] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [changeStatusId, setChangeStatusId] = useState();
  const [filterData, setFilterData] = useState({
    reply: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "chat",
      page: state?.source === "support-ticket" ? t("admin.label.list_tickets") : t("chat.title"),
      pagePath:
        state?.source === "support-ticket"
          ? `/${localStorage.getItem("type")}/tickets`
          : `/${localStorage.getItem("type")}/livechat`,
    },
    {
      id: "answer_reply",
      page: t("chat.answer_reply"),
      active: true,
    },
  ];

  const actionsList = [
    {
      id: "edit",
      icon: <img src={editExhIcon} alt="" />,
      title: t("edit"),
      action: (id) => {
        if (state?.source === "support-ticket") {
          history.push({
            pathname: `/${localStorage.getItem("type")}/livechat/edit-answers-reply/${id}`,
            state: {
              source: "support-ticket",
            },
          });
        } else {
          history.push(`/${localStorage.getItem("type")}/livechat/edit-answers-reply/${id}`);
        }
        dispatch(resetLivechat());
      },
    },
    {
      id: "delete",
      title: t("delete"),
      icon: <img src={deleteIcon} alt="" />,
      action: (id) => {
        setDeletedId(id);
        setShowDeleteModal(true);
      },
    },
  ];

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const statusHandler = async (id, status) => {
    try {
      dispatch(postLivechatReplyStatus(id));
    } catch (err) {}
  };

  const deleteHandler = async (id) => {
    dispatch(deleteLivechatReply(id));
    setIsLoading(true);
    setShowDeleteModal(false);
  };

  const resetFilterHandler = () => {
    setFilterData({
      reply: "",
    });
    setIsResetApplied(true);
    if (state?.source === "support-ticket") {
      dispatch(getSupportTicketReply());
    } else {
      dispatch(getlivechatReply());
    }
  };

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData.id}</>,
    },
    {
      id: "reply",
      name: t("chat.answer_template"),
      key: "img",
      widthT: "100",
      cell: ({ rowData }) => (
        <p
          className={classes.wrapText}
          dangerouslySetInnerHTML={{
            __html: i18n.language === Lang.AR ? rowData.message_ar : rowData.message_en,
          }}
        />
      ),
      sortable: false,
    },
    {
      id: "adding_date",
      name: t("chat.addingdate"),
      key: "adding_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.start_time).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.date)}
        </>
      ),
      sortable: false,
    },

    {
      id: "actions",
      name: t("chat.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <div className={classes["chat_list__actions"]}>
          {actionsList.map((action, index) => {
            return (
              <button
                key={action.id}
                className={classes["chat_list__actions-btn"]}
                title={action.title}
                onClick={() => {
                  if (action.id === "edit" && new Date(rowData.date) <= Date.now()) {
                    return;
                  }
                  action.action(rowData.id);
                }}
                disabled={action.id === "edit" && new Date(rowData.date) <= Date.now()}
              >
                {action.icon}
              </button>
            );
          })}
        </div>
      ),
      sortable: false,
    },

    {
      id: "status",
      name: t("chat.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.active === true ? 1 : 0}
          id={rowData.id}
          onStatusChange={() => {
            setChangeStatusId(rowData.id);
            statusHandler(rowData.id);
          }}
        />
      ),
      sortable: false,
    },
  ];

  const filterFields = [
    {
      id: "reply",
      component: "input",
      label: t("chat.answer_template"),
      type: "text",
      placeholder: t("chat.answer_template"),
      name: "reply",
      value: filterData.reply,
      classes: "col-12 col-md-7",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("chat.reset"),
      classes: "col-12 col-md-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("chat.search"),
      classes: "col-12 col-md-2",
      action: () => {
        if (state?.source === "support-ticket") {
          dispatch(getSupportTicketReply(filterData));
        } else {
          dispatch(getlivechatReply(filterData));
        }
        setCurrentPage(1);
      },
    },
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const deleteObjectById = (idToDelete) => {
    const updatedArray = replies.filter((obj) => obj.id !== idToDelete);
    setReplies(updatedArray);
  };

  useEffect(() => {
    if (state?.source === "support-ticket") {
      dispatch(getSupportTicketReply());
    } else {
      dispatch(getlivechatReply());
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (livechat_reply.status === 200) {
      setReplies(livechat_reply.data.data.replies.data);
      setTotalPages(livechat_reply.data.data.replies.meta.totalPages);
      setIsLoading(false);
    }
  }, [livechat_reply]);

  useEffect(() => {
    if (livechat_delete_reply.status === 200) {
      deleteObjectById(deletedId);
      setIsLoading(false);
    }
  }, [livechat_delete_reply]);

  useEffect(() => {
    setIsResetApplied(false);
  }, [currentPage, isResetApplied]);

  useEffect(() => {
    if (livechat_reply_status.status === 200 && livechat_reply_status.data.status) {
      updateObjectById(changeStatusId, livechat_reply_status.data.reply);
    }
  }, [livechat_reply_status]);

  const updateObjectById = (id, newObject) => {
    setReplies((prevObjects) => prevObjects.map((obj) => (obj.id === id ? newObject : obj)));
  };

  return (
    <div className={classes["chat_list"]}>
      <div className="container-fluid">
        <div className={classes["chat_list__container"]}>
          <div className="row">
            <Breadcrumb list={breadcrumbList} />
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("chat.answer_reply")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    addAnswersReply: () => {
                      if (state?.source === "support-ticket") {
                        history.push({
                          pathname: `/${localStorage.getItem("type")}/livechat/create-answers-reply`,
                          state: {
                            source: "support-ticket",
                          },
                        });
                      } else {
                        history.push(`/${localStorage.getItem("type")}/livechat/create-answers-reply`);
                      }
                      dispatch(resetLivechat());
                    },
                  })}
                />
                {showFilter && <FilterBox fields={filterFields} />}
                <MainTable columns={columns} data={replies} isLoading={isLoading} />
                <Pagination
                  count={totalPages}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
              {showDeleteModal && (
                <SharedModal
                  icon={DeleteIcon}
                  title={t("chat.deleteMsg")}
                  confirmMsg={t("chat.delete_confirmMsg")}
                  confirmText={t("chat.confirm")}
                  cancelText={t("chat.cancel")}
                  onCancel={() => {
                    setShowDeleteModal(false);
                  }}
                  onConfirm={() => {
                    deleteHandler(deletedId);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswersReply;
