import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

function EmailVerification() {
  require("./emailVerification.css");
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state == undefined) {
      history.push("/");
    }
  }, []);

  return location.state ? (
    <>
      <div className="auth-email-verification-container">
        <h1 className="auth-email-verification-title alert-success">{t("EmailVerificationTitle")}</h1>
        <p className="auth-email-verification-body">{t("EmailVerificationBody", { email: location.state.email })}</p>
      </div>
    </>
  ) : null;
}

export default EmailVerification;
