import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom"; // TODO:
import { toast } from "react-toastify";
import PasswordInput from "../../../Components/Input/PasswordInput";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import { checkPasswordResetToken, confirmationEmailChange } from "../../../Services/api/auth/AuthProvider";
import { FooterContext } from "../../../Services/api/toutorials/FooterContext";
import i18n from "../../../i18n/i18n";
import MainBox from "../../Shared/Components/MainBox/MainBox";
require("./forgotPassword.css");

function ForgotPassword() {
  document.body.dir = i18n.dir();
  const { t } = useTranslation();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const email = new URLSearchParams(search).get("email").replace(/ /g, "+");
  const alternative = new URLSearchParams(search).get("alternative");
  console.log(token);

  const authContext = useContext(AuthContext);
  const footerCtx = useContext(FooterContext);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [type, setType] = useState("password");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  useEffect(() => {
    footerCtx.setFooter(false);
    return () => {
      footerCtx.setFooter(true);
    };
  }, []);

  const passwordHandleChange = (confirmPassword) => {
    setPassword(confirmPassword);
  };

  const confirmPasswordHandleChange = (confirmPassword) => {
    setConfirmPassword(confirmPassword);
  };

  useEffect(() => {
    checkPasswordResetToken({ token: token })
      .then((res) => {
        if (res.status === 200) {
          setDisabled(true);
        }
      })
      .catch((error) => {
        history.push("/login");
        console.log(error);
        // toast.error(<span style={{ fontSize: 14 }}>{error.response.data.msg}</span>);
        toast.error(<span style={{ fontSize: 14 }}>{t("invalid_token")}</span>);
      });
  }, []);

  function validateForm() {
    let hasError = false;
    const containsUppercase = /[A-Z]/.test(password);
    const containsLowercase = /[a-z]/.test(password);
    const containsNumber = /\d/.test(password);
    const containsSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);
    const isValidLength = password.length >= 8;

    if (!containsUppercase) {
      setPasswordError(t("password_validation.uppercase"));
      hasError = true;
    } else if (!containsLowercase) {
      setPasswordError(t("password_validation.lowercase"));
      hasError = true;
    } else if (!containsNumber) {
      setPasswordError(t("password_validation.digit"));
      hasError = true;
    } else if (!containsSpecial) {
      setPasswordError(t("password_validation.symbol"));
      hasError = true;
    } else if (!isValidLength) {
      setPasswordError(t("password_validation.long"));
      hasError = true;
    } else {
      setPasswordError("");
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError(t("confirmationPassword"));
      hasError = true;
    } else {
      setConfirmPasswordError("");
    }

    return !hasError;
  }

  const confirmationEmailChanged = () => {
    if (validateForm()) {
      let data = {
        token: token,
        password: password,
        password_confirmation: confirmPassword,
      };

      alternative && (data.alternative = alternative);
      email && (data.email = email);

      confirmationEmailChange(data)
        .then((res) => {
          if (res.status == 200) {
            toast.success(<span style={{ fontSize: 14 }}>{t("emailChanged")}</span>);
            history.push("/login");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(<span style={{ fontSize: 14 }}>{t("emailNotChanged")}</span>);
        });
    }
  };

  const showHide = (e) => {
    e.preventDefault();
    setType(type === "input" ? "password" : "input");
  };

  let spinnerLoading = "";
  if (disabled === true) {
    spinnerLoading = <div className="lds-dual-ring"></div>;
  } else {
    spinnerLoading = "";
  }

  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="col-12">
          <MainBox>
            <>
              <p className="auth_label update-password-label">{t("updatePassword")}</p>
              <div className="auth_form_flex_col">
                <label htmlFor="password">
                  <PasswordInput setContent={passwordHandleChange} />
                  {passwordError && (
                    <div className="text-danger" style={{ fontSize: "13px" }}>
                      {passwordError}
                    </div>
                  )}
                </label>

                <label htmlFor="password">
                  <PasswordInput setContent={confirmPasswordHandleChange} confirmation={true} />
                  {confirmPasswordError && (
                    <div className="text-danger" style={{ fontSize: "13px" }}>
                      {confirmPasswordError}
                    </div>
                  )}
                </label>

                <button
                  type="button"
                  className="button_loading auth_btn forget-password-btn"
                  onClick={confirmationEmailChanged}
                >
                  <div className="">{t("updatePassword")}</div>
                  {spinnerLoading}
                </button>
                {error}
              </div>
            </>
          </MainBox>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
