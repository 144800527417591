import FilterIcon from "../../../assets/icons/filter.svg";
import PdfIcon from "../../../assets/icons/pdf.svg";
import AddIcon from "../../../assets/icons/plus.svg";
import XlsIcon from "../../../assets/icons/xls.svg";
import i18n from "../../../i18n/i18n";

export const filterActions = ({
  addIntroVideo,
  showFilter,
  addAction,
  addActionNotActive,
  exportPdf,
  exportExcel,
  cloneCourse,
  cloneCourseNotActive,
}) => {
  const actionsList = [
    ...(addIntroVideo
      ? [
          {
            id: "introVideo",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #8599B6",
              borderRadius: 6,
              width: i18n.language === "ar" ? 173 : 210,
              height: 50,
            },
            label: i18n.language === "ar" ? "اضافة فيديو تعريفي" : "Add an introductory video",
            btnTextColor: "#8599B6",
            action: addIntroVideo,
          },
        ]
      : []),
    ...(cloneCourse
      ? [
          {
            id: "cloneCourse",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #036c77",
              borderRadius: 6,
              width: 173,
              height: 50,
            },
            label: i18n.language === "ar" ? "نسخ مقرر" : "Clone Course",
            btnTextColor: "#036c77",
            action: cloneCourse,
          },
        ]
      : []),
    ...(cloneCourseNotActive
      ? [
          {
            id: "cloneCourse",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #ccc",
              borderRadius: 6,
              width: 173,
              height: 50,
              color: "#aaa",
            },
            label: i18n.language === "ar" ? "نسخ مقرر" : "Clone Course",
            btnTextColor: "#ccc",
            action: cloneCourseNotActive,
          },
        ]
      : []),
    ...(showFilter
      ? [
          {
            id: "filter",
            icon: <img src={FilterIcon} alt="" />,
            action: showFilter,
            title: i18n.t("filter"),
          },
        ]
      : []),
    ...(addAction
      ? [
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            theme: "add",
            action: addAction,
            title: i18n.t("add"),
          },
        ]
      : []),
    ...(addActionNotActive
      ? [
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            theme: "add",
            style: {
              border: "2px solid #ccc",
              borderRadius: 6,
              width: 173,
              height: 50,
              color: "#aaa",
              backgroundColor: "#ccc",
            },
            action: addActionNotActive,
            title: i18n.t("add"),
          },
        ]
      : []),
    ...(exportPdf
      ? [
          {
            id: "pdf",
            icon: <img src={PdfIcon} alt="" />,
            theme: "pdf",
            action: exportPdf,
            title: i18n.t("export_as_pdf"),
          },
        ]
      : []),
    ...(exportExcel
      ? [
          {
            id: "xls",
            icon: <img src={XlsIcon} alt="" />,
            theme: "xls",
            action: exportExcel,
            title: i18n.t("export_as_xlsx"),
          },
        ]
      : []),
  ];
  return actionsList;
};
