import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import FilterIcon from "../../../../assets/icons/filter.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";

export const FilterActionsRefund = ({ showFilter, addAction, exportPdf, exportExcel }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const actionsList = [
    {
      id: "type",
      type: "button",
      label: t("sidebar.label.refund-setting"),
      color: "#8599B6",
      action: () => {
        history.push(
          `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : localStorage.getItem("type") === "group-admin"
              ? "group-admin"
              : localStorage.getItem("type") === "manager"
              ? "manager"
              : "admin"
          }/refund-setting/list`
        );
      },
    },
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
      title: i18n.t("filter"),
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
      title: i18n.t("export_as_pdf"),
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
      title: i18n.t("export_as_xlsx"),
    },
  ];
  return actionsList;
};
