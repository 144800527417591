import { Checkbox, Radio } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export const BasicMatrix = (props) => {
  require("./style.css");

  const KeyCodes = {
    enter: 13,
  };
  const { t, i18n } = useTranslation();
  const delimiters = [KeyCodes.enter];
  // const [data, setData] = useState(props.matrixData);

  const data = {
    items: [
      { id: 1, name: "Odoo" },
      { id: 2, name: "Microsoft" },
      { id: 3, name: "SAAP" },
    ],
    options: [
      { id: 1, name: "UX" },
      { id: 2, name: "UI" },
      { id: 3, name: "API" },
    ],
    answers: [
      { item_id: 1, option_id: 1 },
      { item_id: 2, option_id: 3 },
    ],
  };

  const dempItems = []; //[{id: "1", name: "Odoo"}, {id: "2", name: "Microsoft"}, {id: "3", name:"SAAP"}];
  const demoOptions = []; //[{id: "1", name: "UX",}, {id: "2", name: "UI"}, {id: "3", name: "API"}];
  const demoItemOptions = []; //[{id: "1", name: "V.Good"}, {id: "2", name: "Good"}, {id: "3", name: "Bad"}];
  const demoAnswers = [
    { item_id: 1, option_id: 1 },
    { item_id: 2, option_id: 3 },
  ];

  const [matrixData, setMatrixData] = useState(data);
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [optionType, setOptionType] = useState([]);

  const [item, setItem] = useState("");
  const [option, setOption] = useState("");
  const [itemOption, setItemOption] = useState("");
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (props.mode == "edit") {
      save();
    }
  }, [items, options, itemOptions]);

  useEffect(() => {
    if (props.question) {
      setItems(props.question.items ?? []);
      setOptions(props.question.options ?? []);
      const localAnswers = props.answers?.filter((answer) => answer.question.id == props.question.id);
      setAnswers(localAnswers || []);
    }
  }, [props.question]);

  const isSelected = (item, option) => {
    let answer = answers.filter((answer) => answer.item.id == item.id && answer.option.id == option.id);
    return answer.length ? true : false;
  };

  const setAnswer = (item, option) => {
    var found = false;
    var answerIndex = -1;
    var answerIndexs = [];
    var currentAnswers = [...answers];

    currentAnswers.map((answer, index) => {
      if (answer.item.id == item.id) {
        // set related item options
        answerIndexs.push(index);
        // set the clicked item option
        if (answer.option.id == option.id) {
          found = true;
          answerIndex = index;
        }
      }
    });

    if (!found) {
      currentAnswers.push({
        item: { id: item.id, name: item.name },
        option: { id: option.id, value: option.value },
        value: option.id,
      });
      // delete others if the selected type is radio

      answerIndexs.forEach((answerIndex) => {
        currentAnswers.splice(answerIndex, 1);
      });
    } else {
      currentAnswers.splice(answerIndex, 1);
    }
    setAnswers(currentAnswers);
    props.updateAnswers(props.question.id, currentAnswers);
  };

  const handleChange = (e) => {
    var action = e.target.name;
    var name = e.target.value;
    if (action == "item") {
      setItem(name);
    } else if (action == "option") {
      setOption(name);
    }
  };

  // To insert new element
  const submitElement = (e) => {
    var action = e.target.name;
    if (e.keyCode === 13) {
      if (action == "item") {
        addItem();
      } else if (action == "option") {
        addOption();
      }
      e.target.value = "";
    }
  };

  // To update current element
  const handleUpdate = (e, index) => {
    var action = e.target.name;
    var name = e.target.value;
    if (e.keyCode === 13) {
      if (name != "") {
        if (action == "item") {
          updateItem(index, name);
        } else if (action == "option") {
          updateOption(index, name);
        }
      } else {
        var re = window.confirm("هل أنت متأكد من رغبتك من حذف هذا العنصر؟");
        if (!re) {
          return;
        }
        if (action == "item") {
          removeItem(index);
        } else if (action == "option") {
          removeOption(index);
        }
      }
    }
  };

  const addItem = () => {
    setItems([...items, { name: item }]);
    setItem("");
  };

  const addOption = () => {
    setOptions([...options, { value: option }]);
    setOption("");
  };

  const updateItem = (index, name) => {
    var currentItems = [...items];
    currentItems.forEach((item, itemIndex) => {
      if (itemIndex == index) {
        item.name = name;
      }
    });
    setItems(currentItems);
  };

  const updateOption = (index, name) => {
    var currentOptions = [...options];
    currentOptions.forEach((option, optionIndex) => {
      if (optionIndex == index) {
        option.value = name;
      }
    });
    setOptions(currentOptions);
  };

  const removeItem = (index) => {
    var filteredItems = items.filter((item, itemIndex) => itemIndex != index);
    setItems(filteredItems);
  };

  const removeOption = (index) => {
    var filteredOptions = options.filter((option, optionIndex) => optionIndex != index);
    setOptions(filteredOptions);
  };

  const save = () => {
    var data = {
      items,
      options,
    };
    props.handleSaveAction(data);
  };

  return (
    <>
      {/* Rendering */}
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            backgroundColor: "#ccc",
            flex: 1,
            alignItems: "center",
          }}
        >
          <div className="matrix-cell lg-view">
            {items.map((item, itemHeadingIndex) => (
              <div className="matrix-row" key={`item-heading-${itemHeadingIndex}`}>
                {props.mode == "edit" && !preview ? (
                  <input
                    defaultValue={item.name}
                    name="item"
                    onKeyUp={(e) => handleUpdate(e, itemHeadingIndex)}
                    style={{ margin: 5, padding: 5, width: "100%" }}
                    placeholder={t("survey.placeholders.item")}
                  />
                ) : (
                  <div className={"matrix-cell"}>{item.name}</div>
                )}
              </div>
            ))}
            {props.mode == "edit" && !preview ? (
              <div className="matrix-row">
                <div className="matrix-cell">
                  <input
                    name="item"
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => submitElement(e)}
                    style={{ width: "100%" }}
                    placeholder={t("survey.placeholders.item")}
                  />
                </div>
              </div>
            ) : null}
          </div>
          {options.map((option, rowIndex) => (
            <>
              {/* Items */}
              <div className="matrix-cell md-view" key={`body-items-${option.id}-${rowIndex}`}>
                {items.map((item, itemHeadingIndex) => (
                  <div className="matrix-row" key={`item-heading-${itemHeadingIndex}`}>
                    <div className="matrix-cell">{item.name}</div>
                  </div>
                ))}
                {props.mode == "edit" && !preview ? (
                  <div className="matrix-row">
                    <div className="matrix-cell">
                      <input
                        name="item"
                        onChange={(e) => handleChange(e)}
                        onKeyDown={(e) => submitElement(e)}
                        placeholder={t("survey.placeholders.item")}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              {/* Options */}
              <div className="matrix-cell" key={`body-options-${option.id}-${rowIndex}`}>
                {props.mode == "edit" && !preview ? (
                  <input
                    name="option"
                    defaultValue={option.value}
                    onKeyUp={(e) => handleUpdate(e, rowIndex)}
                    style={{ width: "100%" }}
                    placeholder={t("survey.placeholders.option")}
                  />
                ) : (
                  <div>{option.value}</div>
                )}
                {items.map((item, itemHeadingIndex) => (
                  <div className="matrix-row" key={`item-body-${itemHeadingIndex}`}>
                    <fieldset>
                      <span className="matrix-cell">
                        {optionType == "checkbox" ? (
                          <Checkbox
                            // name={`item-${item.id}-${option.id}-${item_option.id}`}
                            checked={isSelected(item, option)}
                            onClick={() => setAnswer(item, option)}
                            disabled={!props.canAnswer}
                          />
                        ) : (
                          <Radio
                            // name={`item-${item.id}-${option.id}-${item_option.id}`}
                            checked={isSelected(item, option)}
                            onClick={() => setAnswer(item, option)}
                            disabled={!props.canAnswer}
                          />
                        )}
                      </span>
                    </fieldset>
                  </div>
                ))}
                {/* Add new Item Option & maintain the vertical space */}
                {props.mode == "edit" && !preview ? (
                  <div>
                    <input
                      className="bottom-new-item-option"
                      name="itemOption"
                      onChange={(e) => handleChange(e)}
                      onKeyDown={(e) => submitElement(e)}
                    />
                  </div>
                ) : null}
              </div>
            </>
          ))}
          {props.mode == "edit" && !preview ? (
            <>
              <div className="matrix-cell">
                <div className="matrix-row">
                  <input
                    name="option"
                    className="new-option"
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => submitElement(e)}
                    placeholder={t("survey.placeholders.option")}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>

        {/* Buttons */}
        {/* {props.mode == 'edit' && !preview?
          <div className="row" style={{marginTop: '3%'}}>
                <div className="col-md-6">
                    <button
                        type="button"
                        // disabled={isSubmitting}
                        className="admin_add_button"
                        onClick={save}
                    >
                        حفظ
                    </button>
                </div>
                <div className="col-md-6">
                    <button
                        type="submit"
                        // disabled={isSubmitting}
                        className="admin_add_button"
                    >
                        إلغاء
                    </button>
                </div>
            </div>: null
          } */}
      </div>
    </>
  );
};
