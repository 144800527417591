import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { createCertificateTemplate } from "../../../../Services/api/certificate/certificateTemplateProvider";
import CertificateTemplatesForm from "../CertificateTemplatesForm";

export default function AddCertificateTemplates({ isProgram }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const role = location?.state?.role
    ? location?.state?.role
    : roles.includes("admin")
    ? "admin"
    : roles.includes("manager")
    ? "manager"
    : "trainer";

  const submitHandler = async (values) => {
    let response = await createCertificateTemplate(values);
    if (response?.status === 200 && response?.data.status) {
      history.push({
        pathname: `${
          role === "admin"
            ? "/admin"
            : localStorage.getItem("type") == "group-admin"
            ? `/group-admin`
            : localStorage.getItem("type") == "manager"
            ? `/manager`
            : ""
        }/certificateTemplates${id ? `/${id}` : ""}`,
        state: { role: role },
      });
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.msg
            ? response.data.msg
            : t("certificate.messages.create_successfully")}
        </span>,
        {
          onClose: () => {},
          autoClose: 1000,
        }
      );
    } else {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("certificate.messages.failed_create")}
        </span>
      );
    }
  };

  return (
    <CertificateTemplatesForm
      submitHandler={submitHandler}
      isProgram={isProgram}
    />
  );
}
