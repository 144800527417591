import { Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { contactUs } from "../../../Services/api/toutorials/contactUs";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import Input from "../../Shared/Components/Input/Input";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import "./contactUsForm.css";
import classes from "./ContactUsForm.module.css";

export default function ContactUsForm() {
  const { t, i18n } = useTranslation();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  return (
    <div className={classes.mainCont}>
      <p style={{ color: "#fff", fontSize: "36px", marginBottom: 40 }}>{t("send_message_")}</p>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone_number: "",
          subject: "",
          message: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log(values);
          try {
            let response = await contactUs(values);
            if (response.status === 201 && response.data.status) {
              toast.success(<span style={{ fontSize: 14 }}>{t("contactUsSuccess")}</span>);
              values.name = "";
              values.email = "";
              values.phone_number = "";
              values.subject = "";
              values.message = "";
            } else {
              alert("Failure");
            }
            setSubmitting(false);
          } catch (err) {
            alert(err.response.data && err.response.data.msg ? err.response.data.msg : "Failure in service");
          }
        }}
        validateOnChange={hasSubmitted}
        validate={(values) => {
          setHasSubmitted(true);
          const errors = {};
          //   var pattern = /^\d+$/;
          var pattern = /^\+\d+$/;

          if (!values.name) {
            errors.name = t("crud.errors.required");
          }

          if (!values.email) {
            errors.email = t("crud.errors.required");
          }

          if (!values.phone_number) {
            errors.phone_number = t("crud.errors.required");
          } else if (!pattern.test(values.phone_number)) {
            errors.phone_number = t("crud.errors.fieldNumber");
          }

          if (!values.subject) {
            errors.subject = t("crud.errors.required");
          }

          if (!values.message) {
            errors.message = t("crud.errors.required");
          }

          return errors;
        }}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <>
            <div className={classes.mainForm}>
              <div className={classes.subform}>
                <Input
                  id="name"
                  type="text"
                  name="name"
                  contactUs={true}
                  value={values.name}
                  label={t("name")}
                  placeholder={t("name")}
                  helperText={errors.name ? t("crud.errors.required") : ""}
                  onChange={handleChange}
                />
                <Input
                  id="email"
                  type="text"
                  name="email"
                  contactUs={true}
                  value={values.email}
                  label={t("email")}
                  placeholder={t("email")}
                  helperText={errors.email ? t("crud.errors.required") : ""}
                  onChange={handleChange}
                />
                <Input
                  id="phone_number"
                  name="phone_number"
                  type="text"
                  contactUs={true}
                  value={values.phone_number}
                  label={t("Exhibition.popupphone")}
                  placeholder="+999 123 123 123"
                  error={errors.phone_number}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.subform}>
                <Input
                  id="subject"
                  name="subject"
                  contactUs={true}
                  value={values.subject}
                  label={t("messageTitle")}
                  placeholder={t("messageTitle")}
                  type="text"
                  error={errors.subject}
                  onChange={handleChange}
                />
                <Input
                  id="message"
                  name="message"
                  contactUs={true}
                  value={values.message}
                  label={t("messageSubject")}
                  placeholder={t("messageSubject")}
                  type="text"
                  error={errors.message}
                  onChange={handleChange}
                />
                {/* <TextField
                  id="standard-basic"
                  label="Standard"
                  variant="standard"
                  helperText="Incorrect entry."
                /> */}
              </div>
            </div>
            <div className={classes.btnCont}>
              <CustomButton
                value={t("submit")}
                colors={"#202020b9"}
                type="submit"
                action={() => handleSubmit()}
                classes={classes.btn}
                disable={isSubmitting}
                loading={isSubmitting}
              />
            </div>
            {isSubmitting && <SkeletonCardOverlay />}
          </>
        )}
      </Formik>
    </div>
  );
}
