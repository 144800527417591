import { useContext, useEffect } from "react";
import { AuthContext } from "../../Services/api/auth/AuthContext";
import { FooterContext } from "../../Services/api/toutorials/FooterContext";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import EmailVerification from "./EmailVerification/EmailVerification";
import Login from "./Login/Login";
import Register from "./Register/Register";
import ForgotPassword from "./forgotPassword/ForgotPassword";

function MainAuth(props) {
  require("./mainAuth.css");
  const PropsCom = props;
  const pathLocation = PropsCom.location.pathname;
  const authContext = useContext(AuthContext);

  let FormShow = "";
  if (pathLocation === "/login" || pathLocation === "/Login") {
    FormShow = (
      <>
        <Login />
      </>
    );
  } else if (pathLocation === "/register" || pathLocation === "/Register") {
    FormShow = (
      <>
        <Register />
      </>
    );
  } else if (pathLocation === "/email-verification") {
    FormShow = (
      <>
        <EmailVerification />
      </>
    );
  } else if (pathLocation === "/forgot-password") {
    FormShow = (
      <>
        <ForgotPassword />
      </>
    );
  }

  const footerCtx = useContext(FooterContext);

  useEffect(() => {
    footerCtx.setFooter(false);
    return () => {
      footerCtx.setFooter(true);
    };
  }, []);

  return (
    <div
      className="auth_main_box"
      style={{
        minHeight: "calc(100vh)",
      }}
    >
      <div className="blur-image" />
      <div className="container-fluid">
        {authContext.isPerformingAuthenticationOperation ? <SkeletonCardOverlay borderRadius={20} /> : null}
        <div className="auth-card">
          <div className="auth-inside-card" style={{ display: "flex" }}>
            {FormShow}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainAuth;
