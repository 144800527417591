// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Pagination } from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../Components";
import { updateActiveSupportTicket } from "../../../../Redux/Actions/supportTickets/supportTickets.actions";
import { ticketAction, ticketRate, ticketsExport, ticketsList } from "../../../../Services/api/Tickets/TicketsProvider";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye.svg";
import FilterIcon from "../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import { ReactComponent as ReopenIcon } from "../../../../assets/icons/reopen.svg";
import { ReactComponent as StarIcon } from "../../../../assets/icons/star.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import { utcToLocal } from "../../../../utils/utcToLocal";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import DialogModal from "../../../Shared/Components/DialogModal/DialogModal";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./exhibitionList.module.css";

function Tickets({ isTrainer, fromTrainer = false, isPublic = true }) {
  require("./List.css");

  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const { activeSupportTicketsRole } = useSelector((state) => state.supportTicketsReducer);

  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [isSupport, setIsSupport] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [tickets, setTickets] = useState([]);
  const [surveyFilter, setSurveyFilter] = useState({});

  const [dashboard, setDashboard] = useState({});
  const [filterPayload, setFilterPayload] = useState({});

  const [order, setOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);

  const [ratingRow, setRatingRow] = useState(null);

  const authContext = useContext(AuthContext);
  const [selectedItemToBeReOpened, setSelectedItemToBeReOpened] = useState(null);

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    setSurveyFilter({ ...surveyFilter, [name]: value });
  };

  const checkCanReopen = (row) => {
    return (
      row?.status?.value === "closed" &&
      (localStorage.getItem("type") === "admin" ||
        localStorage.getItem("type") === "manager" ||
        localStorage.getItem("type") === "group-admin" ||
        (isSupport && row?.can_reopen_tickets === 1))
    );
  };

  const actionsList = [
    {
      id: "faq",
      type: "button",
      label: t("support.tickets.dashboard.most_common_faq"),
      style: {
        border: "2px solid #025299",
        borderRadius: 6,
        width: i18n.language === "en" ? 240 : 190,
        height: 50,
        color: "#025299",
      },
      action: () => {
        if (
          localStorage.getItem("type") === "admin" ||
          localStorage.getItem("type") === "manager" ||
          localStorage.getItem("type") === "group-admin"
        ) {
          history.push(`/${localStorage.getItem("type")}/faq-list`);
        } else {
          history.push(`/tickets/faq-list`);
        }
      },
    },
    ...(localStorage.getItem("type") === "admin" ||
    localStorage.getItem("type") === "manager" ||
    localStorage.getItem("type") === "group-admin"
      ? [
          {
            id: "replay",
            type: "button",
            label: t("chat.answer_reply"),
            color: "#025299",
            action: () => {
              history.push({
                pathname: `/${localStorage.getItem("type")}/livechat/answers-reply`,
                state: {
                  source: "support-ticket",
                },
              });
            },
          },
          {
            id: "type",
            type: "button",
            label: t("ticket.tickets_types"),
            color: "#8599B6",
            action: () => {
              history.push(`/${localStorage.getItem("type")}/ticket/types`);
            },
          },
        ]
      : []),
    {
      id: "add",
      type: "button",
      label: (
        <div
          className="tw-flex tw-items-center"
          style={{
            gap: "5px",
          }}
        >
          {t("support.tickets.dashboard.add_new_ticket")}
        </div>
      ),
      color: "#24b3b9",
      action: () => {
        if (
          localStorage.getItem("type") === "admin" ||
          localStorage.getItem("type") === "manager" ||
          localStorage.getItem("type") === "group-admin"
        ) {
          history.push(`/${localStorage.getItem("type")}/ticket/add`);
        } else {
          history.push(`/ticket/add`);
        }
      },
    },

    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      title: t("filter"),
      action: () => {
        setIsFilter((val) => !val);
      },
    },

    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      title: t("export_as_pdf"),
      action: () => {
        setIsUpdating(true);
        ticketsExport({ ...surveyFilter, from_me: activeSupportTicketsRole }, "pdf")
          .then((res) => {
            if (res.status && res.status === 200) {
              setIsUpdating(false);
              toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_success")}</span>);
            } else {
              throw res;
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_faild")}</span>);
          });
      },
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      title: t("export_as_xlsx"),
      action: () => {
        setIsUpdating(true);
        ticketsExport({ ...surveyFilter, from_me: activeSupportTicketsRole }, "xlsx")
          .then((res) => {
            if (res.status && res.status === 200) {
              setIsUpdating(false);
              toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_success")}</span>);
            } else {
              throw res;
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_faild")}</span>);
          });
      },
    },
  ];

  const ticketActionsList = [
    {
      id: "reopen",
      icon: (row) => (
        <ReopenIcon className={`tw-h-5 tw-w-5 tw-fill-[#8599B6] ${checkCanReopen(row) ? "" : "tw-hidden"}`} />
      ),
      title: (row) => t("general.reopen"),
      action: (row) => {
        setSelectedItemToBeReOpened(row);
      },
    },
    {
      id: "view-details",
      icon: (row) => <EyeIcon className="tw-w-5 tw-h-5" />,
      title: (row) => t("general.view_details"),
      action: (row) => {
        if (
          localStorage.getItem("type") === "admin" ||
          localStorage.getItem("type") === "manager" ||
          localStorage.getItem("type") === "group-admin"
        ) {
          history.push(`/${localStorage.getItem("type")}/ticket/view/${row.id}`);
        } else {
          history.push(`/ticket/view/${row.id}`);
        }
      },
    },
    {
      id: "rate",
      icon: (row) => (
        <div className={`tw-flex tw-items-center`}>
          <div className="tw-text-xs tw-text-gray-400">x{row.rate.count}</div>
          <StarIcon
            className={`tw-w-5 tw-h-5 ${
              row.rate.count ? "tw-stroke-[#D5DB3A] tw-fill-white" : "tw-stroke-[#D5D5D5] tw-fill-white"
            }`}
          />
        </div>
      ),
      title: (row) => (row.rate.rate_desc ? row.rate.rate_desc : t("survey.menuEdit.star_rating")),
      action: (row) => {
        // if(!row.canRate) {
        // 	return toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("ticket.cant_rate")}</span>);
        // }
        // if (row.status.value != "closed") {
        // 	return toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("ticket.cant_rate_not_closed")}</span>);
        // }
        if (row.canRate) setRatingRow(row);
      },
    },
  ];

  const columns = [
    // id
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1 + (meta.currentPage - 1) * 10}</>,
    },
    // subject
    {
      id: "subject",
      name: t("crud.list.subject"),
      key: "subject",
      cell: ({ rowData }) => (
        <span className="cell-font tw-w-36 text2lines">
          {i18n.language == Lang.AR ? rowData.subject.name_ar : rowData.subject.name_en}
        </span>
      ),
    },
    // serial
    {
      id: "serial",
      name: t("crud.list.serial"),
      key: "serial",
      sortable: false,
      cell: ({ rowData }) => <span className="cell-font">{rowData.serial}</span>,
    },
    // sender_name
    {
      id: "sender_name",
      name: t("crud.list.sender_name"),
      key: "sender_name",
      cell: ({ rowData }) => (
        <span className="cell-font tw-w-36 text2lines">
          {i18n.language == Lang.AR ? rowData.user.name_ar : rowData.user.name_en}
        </span>
      ),
    },
    // sender_email
    {
      id: "sender_email",
      name: t("crud.list.sender_email"),
      key: "sender_email",
      cell: ({ rowData }) => <span className="cell-font tw-whitespace-nowrap">{rowData.user.email}</span>,
    },
    // ticket_type
    {
      id: "ticket_type",
      name: t("crud.list.ticket_type"),
      key: "ticket_type",
      cell: ({ rowData }) => <span className="cell-font tw-whitespace-nowrap">{rowData.type.name}</span>,
    },
    // created_at
    {
      id: "created_at",
      name: t("crud.list.creation_date"),
      key: "created_at",
      sortable: false,
      cell: ({ rowData }) => (
        <span className="cell-font">
          {rowData?.created_at ? (
            <>
              {moment(rowData?.created_at).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.created_at)}
            </>
          ) : (
            "-"
          )}
        </span>
      ),
    },
    // priority
    {
      id: "priority",
      name: t("crud.list.priority"),
      key: "priority",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap"
          style={{
            color:
              rowData.priority.value == "low" ? "#E62626" : rowData.priority.value == "medium" ? "#24B3B9" : "#12C689",
          }}
        >
          {rowData.priority.text}
        </span>
      ),
    },
    // status
    {
      id: "status",
      name: t("crud.list.status"),
      key: "status",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color:
              rowData.status.value == "new ticket"
                ? "#46BB3E"
                : rowData.status.value == "reviewing"
                ? "#8599B6"
                : rowData.status.value == "waiting client"
                ? "#8599B6"
                : rowData.status.value == "closed"
                ? "#E22020"
                : "#777777",
            backgroundColor:
              rowData.status.value == "new ticket"
                ? "#C1E2C822"
                : rowData.status.value == "reviewing"
                ? "#C1CEE222"
                : rowData.status.value == "waiting client"
                ? "#C1CEE222"
                : rowData.status.value == "closed"
                ? "#E8919122"
                : "#dddddd59",
          }}
        >
          {rowData.status.text}
        </span>
      ),
    },

    // Rating
    // {
    // 	id: "rating",
    // 	name: t("general.rating"),
    // 	key: "rating",
    // 	cell: ({ rowData }) => <>{rowData?.rate?.rate_desc || "-"}</>,
    // 	sortable: false,
    // },
    // last_reply_time
    {
      id: "last_reply_time",
      name: t("crud.list.last_reply"),
      key: "last_reply_time",
      sortable: false,
      cell: ({ rowData }) => (
        <span className="cell-font">
          {rowData?.lastComment && rowData?.lastComment?.date ? (
            <>
              <div>{moment(rowData.lastComment.date).format("DD/MM/YYYY")}</div>
              <div>{utcToLocal(rowData.lastComment.date)}</div>
            </>
          ) : (
            "-"
          )}
        </span>
      ),
    },
    // actions
    {
      id: "actions",
      name: t("Exhibition.actions"),
      key: "actions",
      cell: ({ rowData, rowIndex }) => (
        <div className={`${classes["exhibition_list__actions"]} tw-flex tw-items-center tw-justify-end`}>
          {ticketActionsList.map((action, index) => (
            <IconsTooltip
              title={action.title(rowData)}
              content={
                <button
                  key={action.id}
                  className={classes["exhibition_list__actions-btn"]}
                  onClick={() => {
                    action.action(rowData, rowIndex);
                  }}
                >
                  {action.icon(rowData, rowIndex)}
                </button>
              }
            />
          ))}
        </div>
      ),
    },
  ];
  const filterFields = [
    // ticket_type
    {
      id: "ticket_type",
      component: "select",
      placeholder: t("crud.list.ticket_type"),
      label: t("crud.list.ticket_type"),
      initialValue: t("crud.list.ticket_type"),
      name: "type_id",
      value: surveyFilter.type_id ?? "",
      classes: "col-12 col-md-6 col-lg-4",
      onchange: filterChangeHandler,
      options:
        filterPayload.types?.map((item) => ({
          id: item.id,
          value: item.id,
          label: item.name,
        })) ?? [],
    },
    {
      id: "sender_name",
      component: "input",
      type: "text",
      placeholder: t("crud.list.sender_name"),
      label: t("crud.list.sender_name"),
      name: "sender_name",
      value: surveyFilter.sender_name ?? "",
      classes: "col-12 col-md-6 col-lg-4",
      onReset: () =>
        filterChangeHandler({
          target: { value: "", name: "sender_name" },
        }),
      onchange: filterChangeHandler,
    },
    {
      id: "sender_email",
      component: "input",
      type: "text",
      placeholder: t("crud.list.sender_email"),
      label: t("crud.list.sender_email"),
      name: "sender_email",
      value: surveyFilter.sender_email ?? "",
      classes: "col-12 col-md-6 col-lg-4",
      onReset: () =>
        filterChangeHandler({
          target: { value: "", name: "sender_email" },
        }),
      onchange: filterChangeHandler,
    },
    // subjects
    ...(surveyFilter.type_id
      ? [
          {
            id: "subject_id",
            component: "select",
            placeholder: t("crud.list.subject"),
            label: t("crud.list.subject"),
            initialValue: t("crud.list.subject"),
            name: "subject_id",
            value: surveyFilter.subject_id ?? "",
            classes: "col-12 col-md-6 col-lg-4",
            onchange: filterChangeHandler,
            options:
              filterPayload.types
                ?.find((item) => item.id == surveyFilter.type_id)
                ?.subjects?.map((item) => ({
                  id: item.id,
                  value: item.id,
                  label: i18n.language == Lang.AR ? item.name_ar : item.name_en,
                })) ?? [],
          },
        ]
      : []),
    // serial
    {
      id: "serial",
      component: "input",
      type: "text",
      placeholder: t("crud.list.serial"),
      label: t("crud.list.serial"),
      name: "serial",
      value: surveyFilter.serial ?? "",
      classes: "col-12 col-md-6 col-lg-4",
      onReset: () => filterChangeHandler({ target: { value: "", name: "serial" } }),
      onchange: filterChangeHandler,
    },
    // description
    {
      id: "description",
      component: "input",
      type: "text",
      placeholder: t("crud.list.description"),
      label: t("crud.list.description"),
      name: "text",
      value: surveyFilter.text ?? "",
      classes: "col-12 col-md-6 col-lg-4",
      onReset: () => filterChangeHandler({ target: { value: "", name: "text" } }),
      onchange: filterChangeHandler,
    },

    // creation date
    {
      id: "creation_date",
      component: "datePicker",
      placeholder: t("crud.list.creation_date"),
      label: t("crud.list.creation_date"),
      name: "start_date",
      value: surveyFilter.start_date ?? "",
      classes: "col-12 col-md-6 col-lg-2",
      onchange: filterChangeHandler,
    },
    // last reply
    {
      id: "last_reply",
      component: "datePicker",
      placeholder: t("crud.list.last_reply"),
      label: t("crud.list.last_reply"),
      name: "last_reply_date",
      value: surveyFilter.last_reply_date ?? "",
      classes: "col-12 col-md-6 col-lg-2",
      onchange: filterChangeHandler,
    },
    // status
    {
      id: "status",
      component: "select",
      placeholder: t("crud.list.status"),
      label: t("crud.list.status"),
      initialValue: t("crud.list.status"),
      name: "status",
      value: surveyFilter.status ?? "",
      classes: "col-12 col-md-6 col-lg-2",
      onchange: filterChangeHandler,
      options:
        filterPayload.statuses?.map((item) => ({
          id: item.value,
          value: item.value,
          label: item.text,
        })) ?? [],
    },
    // priority
    {
      id: "priority",
      component: "select",
      placeholder: t("crud.list.priority"),
      label: t("crud.list.priority"),
      initialValue: t("crud.list.priority"),
      name: "priority",
      value: surveyFilter.priority ?? "",
      classes: "col-12 col-md-6 col-lg-2",
      onchange: filterChangeHandler,
      options:
        filterPayload.priorities?.map((item) => ({
          id: item.value,
          value: item.value,
          label: item.text,
        })) ?? [],
    },
    // ...((authContext.inAdminPanel && activeSupportTicketsRole === 0) ||
    // (isSupport && activeSupportTicketsRole === 0)
    //   ? [
    //       // sender_name
    //       {
    //         id: "sender_name",
    //         component: "input",
    //         type: "text",
    //         placeholder: t("crud.list.sender_name"),
    //         label: t("crud.list.sender_name"),
    //         name: "sender_name",
    //         value: surveyFilter.sender_name ?? "",
    //         classes: "col-12 col-md-6 col-lg-4",
    //         onReset: () =>
    //           filterChangeHandler({
    //             target: { value: "", name: "sender_name" },
    //           }),
    //         onchange: filterChangeHandler,
    //       },
    //       // employee name
    //       // {
    //       // 	id: "employee_name",
    //       // 	component: "input",
    //       // 	type: "text",
    //       // 	placeholder: t("crud.list.employee_name"),
    //       // 	label: t("crud.list.employee_name"),
    //       // 	name: "employee_name",
    //       // 	value: surveyFilter.employee_name ?? "",
    //       // 	classes: "col-12 col-md-4",
    //       // 	onReset: () =>
    //       // 		filterChangeHandler({
    //       // 			target: { value: "", name: "employee_name" },
    //       // 		}),
    //       // 	onchange: filterChangeHandler,
    //       // },
    //     ]
    //   : []),
    // reset
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-6 col-lg-2 tw-ms-auto",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        setSurveyFilter({});
        if (currentPage == 1) {
          refetchUsers({});
        } else {
          setCurrentPage(1);
        }
      },
    },
    // submit
    {
      id: "button",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6 col-lg-2",
      action: () => {
        if (currentPage == 1) {
          refetchUsers();
        } else {
          setCurrentPage(1);
        }
      },
    },
  ];

  const fetchUsers = (params = surveyFilter) => {
    params = { ...params, perPage: 10 };
    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await ticketsList(params);
        if (response.status == 200) {
          const tickets = response.data.data.tickets;
          setTickets(tickets.data);
          setMeta(tickets.meta);
          setDashboard(tickets.dashboard);
          setFilterPayload(response.data.data.filter);
          setIsLoading(false);
          setIsUpdating(false);
          setIsSupport(tickets?.is_support === 1);
        }
        resolve();
      } catch (e) {
        reject(e);
        setIsLoading(false);
        setIsUpdating(false);
      }
    });

    return myPromise;
  };

  const sortActionHandler = async (columnKey) => {
    let key = columnKey;

    setOrder({
      order: key,
      dir: order?.order == key && order?.dir == "ASC" ? "DESC" : "ASC",
    });
  };

  const refetchUsers = async (params = surveyFilter) => {
    try {
      let customFilter = {
        ...params,
        ...order,
        from_me: activeSupportTicketsRole,
        page: currentPage,
      };
      await fetchUsers(customFilter);
    } catch (e) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  useEffect(() => {
    refetchUsers();
  }, [currentPage, order, activeSupportTicketsRole]);

  const breadcrumbList = [
    ...(localStorage.getItem("type") === "admin" ||
    localStorage.getItem("type") === "manager" ||
    localStorage.getItem("type") === "group-admin"
      ? [
          {
            id: "home",
            page: <img src={HomeIcon} alt="" />,
            pagePath: "/",
          },
          {
            id: t("admin.label.admin_label"),
            page: t("admin.label.admin_label"),
            pagePath: "/admin",
          },
        ]
      : [
          {
            id: "home",
            page: <img src={HomeIcon} alt="" />,
            pagePath: "/",
          },
        ]),
    {
      id: t("admin.label.list_tickets"),
      page: t("admin.label.list_tickets"),
      active: true,
    },
  ];

  const statistics = [
    {
      label: t("ticket.closed"),
      value: dashboard.completed,
      color: "#025299",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="check-circle_4_"
            data-name="check-circle (4)"
            d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("ticket.new"),
      value: dashboard.new,
      color: "#025299dd",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="add_1_"
            data-name="add (1)"
            d="M33.93,0A33.93,33.93,0,1,0,67.86,33.93,33.93,33.93,0,0,0,33.93,0Zm0,62.205A28.275,28.275,0,1,1,62.205,33.93,28.275,28.275,0,0,1,33.93,62.205ZM48.068,33.93a2.828,2.828,0,0,1-2.828,2.828H36.758V45.24a2.828,2.828,0,1,1-5.655,0V36.758H22.62a2.828,2.828,0,0,1,0-5.655H31.1V22.62a2.828,2.828,0,1,1,5.655,0V31.1H45.24A2.828,2.828,0,0,1,48.068,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("general.followUp"),
      value: dashboard.in_progress,
      color: "#025299cc",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.857 67.863">
          <path
            id="user-pen"
            d="M25.448,33.93A16.965,16.965,0,1,0,8.483,16.965,16.982,16.982,0,0,0,25.448,33.93Zm0-28.275a11.31,11.31,0,1,1-11.31,11.31A11.321,11.321,0,0,1,25.448,5.655ZM30.4,45.862A19.815,19.815,0,0,0,5.655,65.033a2.828,2.828,0,1,1-5.655,0A25.465,25.465,0,0,1,31.81,40.388,2.828,2.828,0,0,1,30.4,45.865ZM65.375,33.588a8.684,8.684,0,0,0-12,0L34.414,52.552a11.242,11.242,0,0,0-3.314,8v4.484a2.829,2.829,0,0,0,2.828,2.828h4.484a11.235,11.235,0,0,0,8-3.314L65.372,45.585a8.485,8.485,0,0,0,0-12Zm-4,8L42.407,60.548a5.607,5.607,0,0,1-4,1.657H36.752V60.548a5.617,5.617,0,0,1,1.657-4L57.376,37.586a2.893,2.893,0,0,1,4,0,2.826,2.826,0,0,1,0,4Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("general.reopen"),
      value: dashboard.reopened,
      color: "#025299bb",
      icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
    },
  ];

  function RatingModal({}) {
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [over, setOver] = useState(0);

    return (
      <DialogModal
        onCancel={() => setRatingRow(null)}
        className="tw-bg-white sm:tw-rounded-lg tw-shadow-xl tw-p-4 tw-space-y-4 tw-w-full sm:tw-w-2/3 lg:tw-w-1/2"
      >
        <div>
          <div className="tw-text-lg sm:tw-text-xl tw-text-teal-600">{t("add_rate")}</div>
        </div>
        <div>
          <Formik
            initialValues={{
              rate: ratingRow.count <= 0 ? 3 : ratingRow.count > 5 ? 5 : ratingRow.count,
              rate_dislike_text: ratingRow.rate_dislike_text,
              rate_like_text: ratingRow.rate_like_text,
            }}
            onSubmit={async (values) => {
              try {
                let response = await ticketRate(ratingRow.id, values);
                if (response.status == 200 && response.data.status) {
                  toast.success(<span style={{ fontSize: 15 }}>{t("rating_success")}</span>);
                  setRatingRow(null);
                  setTickets((prev) => {
                    return prev.map((ticket) => {
                      if (ticket.id == ratingRow.id) {
                        return {
                          ...ticket,
                          rate: {
                            ...ticket.rate,
                            count: values.rate,
                            rate_desc: values.rate_like_text,
                          },
                        };
                      } else {
                        return ticket;
                      }
                    });
                  });
                } else {
                  toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("rating_failed")}</span>);
                }
              } catch (err) {
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.errors != null &&
                  typeof err.response.data.errors === "object" &&
                  !Array.isArray(err.response.data.errors)
                ) {
                  const issues = [];
                  Object.keys(err.response.data.errors).forEach((key) => {
                    issues.push(Array.isArray(err.response.data.errors[key]) ? err.response.data.errors[key][0] : "");
                  });
                  toast.error(
                    <>
                      <span style={{ fontSize: 14 }}>{err.response.data.msg}:</span>
                      <ul>
                        {issues.map((item, index) => {
                          return (
                            <li key={index}>
                              <span style={{ fontSize: 12 }}>{item}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  );
                } else if (typeof err === "string") {
                  toast.error(<span style={{ fontSize: 14 }}>{err}</span>);
                }
              }
            }}
            validateOnChange={hasSubmitted}
            validate={(values) => {
              setHasSubmitted(true);
              const errors = {};
              if (!values.rate) {
                errors.rate = t("crud.errors.required");
              }

              return errors;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setErrors,
            }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <div
                    className="tw-py-4 tw-px-2 tw-flex tw-items-center tw-space-s-3 tw-w-full"
                    onMouseLeave={() => setOver(0)}
                  >
                    {[...Array(5).keys()].map((item, index) => (
                      <button
                        type="button"
                        key={index}
                        onMouseOver={() => setOver(index + 1)}
                        onClick={() => {
                          setFieldValue(`rate`, index + 1);
                        }}
                      >
                        <StarIcon
                          className={`tw-h-10 tw-w-10 hover:tw-w-12 hover:tw-h-12 hover:-tw-m-1 ${
                            (over ? over : values.rate) > index
                              ? "tw-fill-teal-400 tw-stroke-teal-400"
                              : "tw-fill-white tw-stroke-gray-500"
                          }`}
                        />
                      </button>
                    ))}
                  </div>
                  <div className={"form-input-error-space"}>{errors.rate ? errors.rate : null}</div>
                </div>
                {/* reply field */}
                <div>
                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("general.comment")}</div>
                  {/* <SunEditor
										placeholder={t("general.comment")}
										value={values.rate_like_text}
										defaultValue={values.rate_like_text}
										onChange={(e) => {
											setFieldValue("rate_like_text", e);
										}}
										setOptions={{
											height: 150,
											buttonList: [
												["bold", "underline", "italic", "strike", "align", "horizontalRule", "list", "lineHeight"],
											],
										}}
										setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px; text-align: right; direction: rtl"
									/> */}
                  <textarea
                    placeholder={t("general.comment")}
                    value={values.rate_like_text}
                    onChange={(e) => {
                      setFieldValue("rate_like_text", e.target.value);
                    }}
                    rows={5}
                    className="tw-block tw-w-full tw-py-2 tw-px-3 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="tw-flex tw-items-center tw-justify-end tw-space-s-4 tw-pt-8">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="tw-bg-teal-400 tw-py-2 tw-px-16 tw-rounded tw-text-white tw-w-full"
                  >
                    {t("ok")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div></div>
      </DialogModal>
    );
  }

  const reOpenTicket = async () => {
    try {
      const payload = {
        type: "reopen ticket",
      };

      await ticketAction(payload, selectedItemToBeReOpened.id);

      setSelectedItemToBeReOpened(null);
      toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("openTicketSuccess")}</span>);
      refetchUsers();
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {typeof err === "string" ? (
            <span style={{ fontSize: 12, fontWeight: "500", display: "block" }}>{err}</span>
          ) : err.data ? (
            <>
              <span style={{ fontSize: 13, fontWeight: "bold", display: "block" }}>{err.data.msg}</span>
              <ul>
                {Object.keys(err.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            t("Failure_in_service")
          )}
        </span>
      );
    }
  };

  const ticketsRolesOptions = [
    {
      id: "tickets_send_as_user",
      label: t("support.tickets.dashboard.tickets_send_as_user"),
      value: 1,
    },
    {
      id: "tickets_send_as_representative",
      label:
        localStorage.getItem("type") === "admin" ||
        localStorage.getItem("type") === "manager" ||
        localStorage.getItem("type") === "group-admin"
          ? t("support.tickets.dashboard.all_support_tickets")
          : t("support.tickets.dashboard.tickets_send_as_representative"),
      value: 0,
    },
  ];

  return (
    <>
      <div className="tw-ms-auto"></div>
      {ratingRow ? <RatingModal /> : <></>}
      {selectedItemToBeReOpened && (
        <SharedModal
          iconCom={<ReopenIcon className={`tw-h-16 tw-w-16 tw-fill-[#8599B6]`} />}
          title={t("modal.support.tickets.reopen.title")}
          name={selectedItemToBeReOpened.title}
          confirmMsg={t("modal.support.tickets.reopen.body")}
          confirmText={t("modal.common.reopen")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setSelectedItemToBeReOpened(null);
          }}
          onConfirm={() => {
            reOpenTicket();
          }}
          color={"#025299"}
          // isLoading={iseDeleteLoading}
        />
      )}
      <div className="container-fluid" id={"content-section"} style={isPublic ? { marginTop: "2rem" } : {}}>
        {!isPublic && (
          <div className="row">
            <div className="col-12 sm:tw-px-2 tw-py-8">
              {/*TODO*/}
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
        )}
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <div className="row">
            <div className="col-12">
              <div className="tw-space-y-4 tw-mb-8">
                {(localStorage.getItem("type") === "admin" ||
                  localStorage.getItem("type") === "manager" ||
                  localStorage.getItem("type") === "group-admin" ||
                  isSupport) && (
                  <MainBox>
                    <div className={classes["tickets-options"]}>
                      <h3 className={classes["tickets-options-title"]}>
                        {t("support.tickets.dashboard.select_ticket_option")}
                      </h3>
                      <div className={classes["tickets-selection"]}>
                        <Select
                          id="ticketsRolesOptions"
                          defaultValue={{
                            label: ticketsRolesOptions?.find((item) => item.value === activeSupportTicketsRole)?.label,
                            value: ticketsRolesOptions?.find((item) => item.value === activeSupportTicketsRole)?.value,
                          }}
                          options={ticketsRolesOptions}
                          onChange={(item) => {
                            if (item) {
                              dispatch(updateActiveSupportTicket(item.value));
                            }
                          }}
                          placeholder={t("support.tickets.dashboard.select_ticket_option")}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </MainBox>
                )}
                <div className="tw-bg-white tw-rounded tw-shadow tw-p-8 tw-space-y-4">
                  <div className=" tw-text-2xl">{t("general.statistics")}</div>
                  <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
                    {statistics.map((item) => (
                      <div
                        className="tw-flex tw-items-center tw-space-s-4 tw-p-4 tw-text-white tw-rounded"
                        style={{ backgroundColor: item.color }}
                      >
                        <div className="tw-h-10 tw-w-10">{item.icon}</div>
                        <div>
                          <div className="tw-text-2xl">{item.value}</div>
                          <div className="tw-text-sm">{item.label}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <MainBox>
                  <MainBoxHead title={t("admin.label.list_tickets")} actions={actionsList} />
                  {isFilter && <FilterBox fields={filterFields} />}
                  <MainTable columns={columns} data={tickets} sortAction={sortActionHandler} />
                  <Pagination
                    count={meta.totalPages}
                    page={currentPage}
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    className="main-pagination"
                    onChange={(e, page) => {
                      setCurrentPage(page);
                    }}
                  />
                </MainBox>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default Tickets;
