import FilterIcon from "../../../assets/icons/filter.svg";
import AddIcon from "../../../assets/icons/plus.svg";
import i18n from "../../../i18n/i18n";

export const filterActions = ({ showFilter, addAction, exportPdf, exportExcel }) => {
  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      theme: "filter",
      action: () => {
        showFilter();
      },
      title: i18n.t("filter"),
    },
    ...(addAction
      ? [
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            theme: "add",
            action: addAction,
            title: i18n.t("add"),
          },
        ]
      : []),

    // {
    //   id: "pdf",
    //   icon: <img src={PdfIcon} alt="" />,
    //   theme: "pdf",
    //   action: exportPdf,
    // },
    // {
    //   id: "xls",
    //   icon: <img src={XlsIcon} alt="" />,
    //   theme: "xls",
    //   action: exportExcel,
    // },
  ];
  return actionsList;
};
