import PdfIcon from "../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import i18n from "../../../../i18n/i18n";

export const filterActions = ({ exportPdf, exportExcel }) => {
  const actionsList = [
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
      title: i18n.t("export_as_pdf"),
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
      title: i18n.t("export_as_xlsx"),
    },
  ];
  return actionsList;
};
