import { useTranslation } from "react-i18next";
import "suneditor/dist/css/suneditor.min.css";
import { downloadInvoice } from "../../../../Services/api/program/programContext";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import Tabs from "../../../Shared/Components/Tabs/Tabs";
import classes from "./invoice.module.css";

const InvoicesTable = ({ activeTab, setActiveTab, invoices, loading }) => {
  const { t, i18n } = useTranslation();

  const tabs = [
    {
      id: "course",
      key: "course",
      label: t("courses"),
    },
    {
      id: "program_payment",
      key: "program_payment",
      label: t("program"),
    },
  ];

  const displayPaymentType = {
    course: t("trainer.course.label"),
    program_payment: t("trainer.program.label"),
    booth: t("booth.label"),
    exhibition_visit: t("Exhibition.label"),
    wallet: t("general.wallet"),
  };

  const paymentDisplay = {
    Bank: t("general.bank"),
    Wallet: t("general.wallet"),
    Tebx: t("tebX"),
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "payment_type",
      name: t("payments.payable_type"),
      key: "payment_type",
      cell: ({ rowData }) => <>{displayPaymentType[rowData?.payable_type] || "-"}</>,
    },
    {
      id: "payable_name",
      name: t("payments.payable_name"),
      key: "payable_name",
      cell: ({ rowData }) => <div>{rowData?.payable_name}</div>,
    },
    {
      id: "payment_method",
      name: t("payments.payment_method"),
      key: "payment_method",
      cell: ({ rowData }) => (
        <div className={classes["payment-method"]}>
          {rowData.payment_method === "Bank" ? (
            <button>{paymentDisplay[rowData.payment_method] || "-"}</button>
          ) : (
            <p
              style={{
                margin: 0,
              }}
            >
              {paymentDisplay[rowData.payment_method] || "-"}
            </p>
          )}
        </div>
      ),
      sortable: false,
    },
    ...(activeTab === "course"
      ? []
      : [
          {
            id: "payment_courses",
            name: t("payments.payment_courses"),
            key: "payment_courses",
            cell: ({ rowData }) => <p>{rowData?.covered_courses?.map((course) => course.name).join(" | ") || "-"}</p>,
          },
        ]),
    {
      id: "invoice_tb",
      name: t("sponsor.invoice.invoice_tb"),
      key: "invoice_tb",
      cell: ({ rowData }) => (
        <p style={{ color: "#24b3b9", cursor: "pointer" }} onClick={() => downloadFile(rowData.id)}>
          {t("invoice_display")}
        </p>
      ),
    },
  ];

  const downloadFile = (id) => {
    downloadInvoice(id);
  };

  return (
    <div className={classes["departments-trainees__tables"]}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onSelectTab={(key) => {
          setActiveTab(key);
        }}
      />
      <MainTable columns={columns} data={invoices || []} isLoading={loading} />
    </div>
  );
};

export default InvoicesTable;
