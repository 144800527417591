import FilterIcon from "../../../assets/icons/filter.svg";
import AddIcon from "../../../assets/icons/plus.svg";
import i18n from "../../../i18n/i18n";

export const filterActions = ({ showFilter, addAnswersReply }) => {
  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
      title: i18n.t("filter"),
    },
    {
      id: "add",
      icon: <img src={AddIcon} alt="" />,
      theme: "add",
      action: addAnswersReply,
      title: i18n.t("add"),
    },
  ];
  return actionsList;
};
