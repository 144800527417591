import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {NavLink} from "react-router-dom";
import {Formik} from "formik";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import clearEmptyProp from "../../../../../Helpers/ClearEmptyProp";
import {toast} from "react-toastify";
import {BasicModal} from "../../../../Shared/Components/Modal/BasicModal";
import DeleteModal from "../../../../../Modals/DeleteModal/DeleteModal";
import IconTooltips from '../../../../../Components/tooltips/IconsTooltip'
import Pdf from './../../../../../assets/image/rating/pdf.png'
import Xls from './../../../../../assets/image/rating/xls.png'
import ReactPaginate from "react-paginate";
import {deleteTax, exportTax, getTax, getTaxById} from "../../../../../Services/api/Financial/FinancialProvider";
import TaxCoursesModal from "../../../../../Modals/taxCoursesModal/TaxCoursesModal";
import Select from "react-select";

function List(props) {
    require("./List.css");
    const { t } = useTranslation();
    const history = useHistory();
    const coursesTaxModalRef = useRef();
    const deleteModalRef = useRef();
    const [meta, setMeta] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const [showCourses, setShowCourses] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({})
    const [tax, setTax] = useState([])
    const [taxId, setTaxId] = useState("");
    const [taxIndex, setTaxIndex] = useState("");
    const [showFilters, setShowFilters] = useState(false);
    const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] = useState(false)
    const [statuses] = useState([{ text: t("financial.tax.not_applied"), value: 0 }, { text: t("financial.tax.applied"), value: 1 },]);

    const fetchAllTax = (params = null) => {
        setIsUpdating(true)
        return new Promise((resolve, reject) => {
            getTax(clearEmptyProp(params))
                .then((res) => {
                    if (res.status && res.status == 200 && res.data.status) {
                        setTax(res.data.data.data);
                        setMeta(res.data.data.meta);
                        setIsLoading(false);
                        setIsUpdating(false)
                        resolve();
                    } else {
                        reject("Error");
                    }
                })
                .catch((err) => {
                    toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("failed_fetching")}
                        </span>
                    );
                });
        });
    };

    useEffect(() => {
        fetchAllTax(currentFilter);
    }, []);


    const editHandler = (id) => {
        history.push(`${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}/tax/edit/${id}`);
    };

    const deleteHandler = (id, index) => {
        setTaxId(id);
        setTaxIndex(index)
        coursesTaxModalRef.current.showModal();
        setShowCourses(false)
    };

    const deleteDate = (id, index) => {
        deleteTax(id)
            .then(() => {
                const newTax = tax.filter((item, i) => i !== index);
                setTax(newTax);
                coursesTaxModalRef.current.dismissModal();
                toast.success(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {t("financial.tax.deleteMessage")}
                    </span>
                );
            })
            .catch((err) => {
                coursesTaxModalRef.current.dismissModal();
                toast.error(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {err.data.msg}
                    </span>
                );
            });
    };

    useEffect(() => {
        if (!isLoading) {
            document.getElementById('content-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
                parseInt(document.getElementById('content-header-bar').offsetHeight) + 40
            ).toString() + 'px';

            document.getElementById('content-body-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight)
            ).toString() + 'px'
        }
    }, [isLoading])

    useEffect(() => {
        if (!isLoading) {
            reAlignElementsHeights(showFilters)
        }
    }, [showFilters])


    const reAlignElementsHeights = (isFilterShown) => {
        if (!isFilterShown) {
            document.getElementById('content-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
                parseInt(document.getElementById('content-header-bar').offsetHeight) + 40
            ).toString() + 'px';

            document.getElementById('content-body-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight)
            ).toString() + 'px'
        } else {
            document.getElementById('content-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) +
                parseInt(document.getElementById('content-header-bar').offsetHeight) +
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) + 80
            ).toString() + 'px';

            document.getElementById('content-body-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
                parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) + 40
            ).toString() + 'px'
        }
    }

    const showAllCourses = (tax) => {
        // setCourses(tax.courses)
        getTaxById(tax)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setCourses(res.data.response.except_Courses)
            coursesTaxModalRef.current.showModal();
            setShowCourses(true)
          }
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("failed_fetching")}
            </span>
          );
        });

    }

    const handlePageClick = (event) => {
        const toBeUpdatedFilters = currentFilter
        toBeUpdatedFilters.page = event.selected + 1
        setCurrentFilter(toBeUpdatedFilters);
        fetchAllTax(toBeUpdatedFilters)
    };

    const modalHandler = () => deleteModalRef.current.dismissModal();


    return (
        <>
            <BasicModal ref={coursesTaxModalRef}>
                {!showCourses ? (
                    <DeleteModal
                        label={t("financial.tax.approve")}
                        title={t("financial.tax.label_popup")}
                        deleteMsg={t("financial.tax.msg_popup")}
                        refuse={modalHandler}
                        accept={() => deleteDate(taxId, taxIndex)}
                        deleteModalRef={coursesTaxModalRef}
                    />
                ) : (
                    <TaxCoursesModal
                        label={t("coupon.label.courses")}
                        title={t("coupon.label.courses")}
                        body={courses}
                        coursesTaxModalRef={coursesTaxModalRef}
                    />
                )}
            </BasicModal>
            <div className="container-fluid" id={"content-section"} style={{
                position: 'relative',
                padding: 0,
                paddingLeft: 0,
                paddingRight: 0,
                marginBottom: 50
            }}>
                <div className="admin_flex" id="content-header-bar">
                    <div className="admin_label">
                        <NavLink to={`${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}`}>
                            {t("admin.label.admin_label")}
                        </NavLink>
                        <p style={{ padding: "0 5px" }}>/</p>
                        {t("financial.tax.tax")}
                    </div>
                    <div className="admin_button_flex">
                        <NavLink
                            to={`${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}/tax/add`}
                            className="text-white admin_button_green_tax"
                        >
                            <i className="fas fa-plus"></i>
                            <span>{t("financial.tax.add_tax")}</span>
                        </NavLink>
                    </div>
                </div>
                {isUpdating ?<SkeletonCardOverlay skeletonWidth="100"/>:<div></div>}
                {isLoading == false ? (
                    <div id="content-body-section" style={{
                        position: 'absolute',
                        width: '100%'
                    }}>
                        {
                            isPerformingBackgroundOperation ?
                                <SkeletonCardOverlay borderRadius={10} top={40} /> : null
                        }
                        <div className={"tajah_table_fade_change"} style={{
                            opacity: showFilters ? 1 : 0
                        }}>
                            <Formik
                                initialValues={{
                                    name: "",
                                    course_name: "",
                                    value: "",
                                    is_default: null
                                }}

                                onSubmit={async (values, { setSubmitting }) => {
                                    try {
                                        // setIsPerformingBackgroundOperation(true);
                                        setCurrentFilter(values);
                                        await fetchAllTax(values);
                                        reAlignElementsHeights(showFilters);
                                        // setIsPerformingBackgroundOperation(false);
                                    } catch (e) {
                                        toast.error(
                                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                                {t("failed_fetching")}
                                            </span>
                                        );
                                    }
                                }}
                                validateOnChange={false}
                            >
                                {({
                                    values,
                                    handleSubmit,
                                    setFieldValue
                                }) => (
                                    <form onSubmit={handleSubmit} class={"table_bank_tran"}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            width: '90%',
                                            borderBottomWidth: 1,
                                            borderBottomColor: "rgba(0,0,0,0.3)",
                                            borderBottomStyle: "solid",
                                            height: 40
                                        }}>
                                            <h5>{t("Filter")}</h5>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                            width: '90%',
                                            marginTop: 30
                                        }}>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    marginRight: document.body.dir == "rtl" ? 10 : 0,
                                                    marginLeft: document.body.dir == "ltr" ? 10 : 0,
                                                }}
                                            >
                                                <label>{t("financial.tax.status")}</label>
                                                <Select
                                                    name="is_default"
                                                    id="is_default"
                                                    options={statuses}
                                                    getOptionLabel={(option) => option.text}
                                                    getOptionValue={(option) => option.value}
                                                    isClearable={true}
                                                    onChange={(status) => {
                                                        if (status) {
                                                            setFieldValue("is_default", status.value);
                                                        } else {
                                                            setFieldValue("is_default", null);
                                                        }
                                                    }}
                                                    placeholder={t("financial.tax.status")}
                                                />
                                            </div>
                                            <div style={{
                                                flex: 1,
                                                marginRight: document.body.dir == 'rtl' ? 10 : 0,
                                                marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                                            }}>
                                                <label>{t("financial.tax.name")}</label>
                                                <input
                                                    id="name"
                                                    type="text"
                                                    name="name"
                                                    className="admin_filter_input"
                                                    onChange={(e) => {
                                                        setFieldValue("name", e.target.value)
                                                    }}
                                                    placeholder={t("financial.tax.name")}
                                                    value={values.name}
                                                />
                                            </div>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                            width: '90%',
                                            marginTop: 30
                                        }}>
                                            <div style={{
                                                flex: 1,
                                                marginRight: document.body.dir == 'rtl' ? 10 : 0,
                                                marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                                            }}>
                                                <label>{t("financial.tax.value")}</label>
                                                <input
                                                    id="value"
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    name="value"
                                                    className="admin_filter_input"
                                                    onChange={(e) => {
                                                        setFieldValue("value", e.target.value)
                                                    }}
                                                    placeholder={t("financial.tax.value")}
                                                    value={values.value}
                                                />
                                            </div>

                                            <div style={{
                                                flex: 1,
                                                marginRight: document.body.dir == 'rtl' ? 10 : 0,
                                                marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                                            }}>
                                                <label>{t("financial.tax.course_name")}</label>
                                                <input
                                                    id="course_name"
                                                    type="text"
                                                    name="course_name"
                                                    className="admin_filter_input"
                                                    onChange={(e) => {
                                                        setFieldValue("course_name", e.target.value)
                                                    }}
                                                    placeholder={t("financial.tax.course_name")}
                                                    value={values.course_name}
                                                />
                                            </div>


                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-end",
                                            width: '90%',
                                            marginTop: 20
                                        }}>
                                            <button
                                                style={{
                                                    width: '100%'
                                                }}
                                                type="submit"
                                                className="admin_add_button"
                                            >
                                                {t("crud.placeholders.submit")}
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>

                        <div className="table_bank_tran tajah_table_vertical_movement" style={{
                            top: showFilters ? parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) + 40 : 0
                        }}>
                            <div className="tajah_card_title_container">
                                <div>{t("financial.tax.tax")}</div>
                                <div className={"tajah_card_title_container_actions"}>
                                    <button style={{ borderRadius: 10, padding: 10, borderWidth: 0 }} className={showFilters ? "btn btn-sm btn-danger" : "btn-bg-filter"}
                                        onClick={() => {
                                            setShowFilters(!showFilters);
                                        }}>
                                        <IconTooltips
                                            title={t('Filter')}
                                            content={<i className={"fas fa-filter tajah_ticket_list_table_filter_icon"} />}
                                        />

                                    </button>
                                    <button className={"btn btn-sm btn-primary color-button-excel"} style={{
                                        marginRight: document.body.dir == 'rtl' ? 10 : 0,
                                        marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                                        backgroundColor: "transparent",
                                        borderColor: "transparent",
                                        borderWidth: 0
                                    }} onClick={async () => {
                                        try {
                                            setIsPerformingBackgroundOperation(true);
                                            await exportTax('xlsx', clearEmptyProp(currentFilter));
                                            setIsPerformingBackgroundOperation(false);
                                        } catch (e) {
                                            setIsPerformingBackgroundOperation(false);
                                            toast.error(
                                                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                                    {t("failed_fetching")}
                                                </span>
                                            );
                                        }
                                    }}>
                                        <IconTooltips
                                            title={t('modal.coupon.hover.titleDownloadExcel')}
                                            content={<img src={Xls} style={{ width: 25 }} />}
                                        />
                                    </button>
                                    <button className={"btn btn-sm btn-primary color-button-pdf"} style={{
                                        marginRight: document.body.dir == 'rtl' ? 10 : 0,
                                        marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                                        backgroundColor: "transparent",
                                        borderColor: "transparent",
                                        borderWidth: 0
                                    }} onClick={async () => {
                                        try {
                                            setIsPerformingBackgroundOperation(true);
                                            await exportTax('pdf', clearEmptyProp(currentFilter));
                                            setIsPerformingBackgroundOperation(false);
                                        } catch (e) {
                                            setIsPerformingBackgroundOperation(false);
                                            toast.error(
                                                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                                    {t("failed_fetching")}
                                                </span>
                                            );
                                        }
                                    }}>
                                        <IconTooltips
                                            title={t('modal.coupon.hover.titleDownloadPdf')}
                                            content={<img src={Pdf} style={{ width: 25 }} />}
                                        />
                                    </button>
                                </div>
                            </div>

                            <div className="table-responsive" style={{
                                width: '90%'
                            }}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{t("financial.tax.name")}</th>
                                            <th scope="col">{t("financial.tax.tax_value")}</th>
                                            {/* <th scope="col">{t("financial.tax.status")}</th> */}
                                            <th scope="col">{t("financial.tax.tax_release")}</th>
                                            <th scope="col">{t("financial.tax.tax_course")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tax.map((tax, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td scope="row">{index + 1}</td>
                                                    <td>{tax.name}</td>
                                                    <td>{(+tax.value).toFixed(1) + "%"}</td>
                                                    {/* {tax.is_default == 1 ? (
                                                        <td><span style={{ color: '#019501' }}>{t("financial.tax.applied")}</span></td>
                                                    ) : (
                                                        <td><span style={{ color: '#D20101' }}>{t("financial.tax.not_applied")}</span></td>
                                                    )} */}
                                                    <td className="">
                                                        <div className="release_icon">
                                                            <IconTooltips
                                                                title={t('modal.coupon.buttons.edit')}
                                                                content={<i
                                                                    className="fal fa-edit"
                                                                    onClick={() => editHandler(tax.id)}
                                                                    style={{
                                                                        marginLeft: 5, marginRight: 5, backgroundColor: 'white',
                                                                        borderWidth: 0, fontSize: 20, color: 'black', cursor: 'pointer'
                                                                    }}
                                                                ></i>}
                                                            />
                                                            <IconTooltips
                                                                title={t('modal.coupon.buttons.delete')}
                                                                content={<i
                                                                    className="fal fa-trash-alt"
                                                                    onClick={() => deleteHandler(tax.id, index)}
                                                                    style={{
                                                                        marginLeft: 5, marginRight: 5, backgroundColor: 'white',
                                                                        borderWidth: 0, fontSize: 20, color: 'red', cursor: 'pointer'
                                                                    }}
                                                                ></i>}
                                                            />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="release_icon">
                                                            <IconTooltips
                                                                title={t('modal.coupon.buttons.data')}
                                                                content={<i
                                                                    className="fal fa-eye"
                                                                    // onClick={() => showAllCourses(tax)}
                                                                    onClick={() => showAllCourses(tax.id)}
                                                                    style={{
                                                                        marginLeft: 5, marginRight: 5, backgroundColor: 'white',
                                                                        borderWidth: 0, fontSize: 20, color: 'black', cursor: 'pointer'
                                                                    }}
                                                                ></i>}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <ReactPaginate
                                    nextLabel={t("next")}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={meta.totalPages}
                                    previousLabel={t("previous")}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mt-5" style={{
                        height: "65vh"
                    }}>
                        <SkeletonCard />
                    </div>
                )}
            </div>
        </>
    )
}

export default List;

