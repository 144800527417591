import React, { useContext, useState } from "react";
import { LessonContentContext } from "../../Providers/LessonContentProvider";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Formik } from "formik";

import UploadAdapter from "../../CKEditor/Adapter/UploadAdapter";
import Select from "react-select";
import SunEditor from "suneditor-react";
import i18n from "../../i18n/i18n";

function EditActivityToLessonModal(props) {
  require("./EditActivityToLessonModal.css");
  const lessonContentContext = useContext(LessonContentContext);

  const { t } = useTranslation();
  const { id } = useParams();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLocalSubmitting, setIsLocalSubmitting] = useState(false);
  const [requireApprovalValues, setRequireApprovalValues] = useState([
    { name: t("yes"), value: 1 },
    { name: t("no"), value: 0 },
  ]);
  return (
    <>
      <div
        className={"tajah-basic-modal-mui"}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={"tajah-basic-modal-header"}>
          <h4>{props.title}</h4>
          <div>
            <i
              className="fas fa-times tajah-modal-dismiss"
              onClick={() => {
                props.dismissModal();
              }}
            ></i>
          </div>
        </div>
        <div className={"tajah-basic-modal-body"}>
          <Formik
            initialValues={{
              name: props.activity.name,
              description: props.activity.description,
              require_approval: props.activity.require_approval,
              max_size: props.activity.max_size,
              rpu: props.activity.rpu,
              lesson_id: id,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setIsLocalSubmitting(true);
              let formData = new FormData();
              Object.keys(values).forEach((field) => {
                formData.append(field, values[field]);
              });

              try {
                await lessonContentContext.editActivity(
                  formData,
                  props.activity.id
                );
                await lessonContentContext.fetchLessonActivities(id);
                props.dismissModal();
              } catch (error) {
                console.log(error);
                setIsLocalSubmitting(false);
                setSubmitting(false);
                return;
              }
            }}
            validate={(values) => {
              setHasSubmitted(true);
              const errors = {};
              if (!values.name) {
                errors.name = t("crud.errors.required");
              }

              if (values.require_approval === "") {
                errors.require_approval = t("crud.errors.required");
              }

              if (!values.max_size) {
                errors.max_size = t("crud.errors.required");
              }

              if (!values.rpu) {
                errors.rpu = t("crud.errors.required");
              }

              return errors;
            }}
            validateOnChange={hasSubmitted}
          >
            {({
              setFieldValue,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <div>
                    <div>
                      <div className={"attachment_panel_input_label"}>
                        <span>{t("trainer.course.lesson.activity.name")}</span>
                        <span className={"attachment_panel_asterisk_input"}>
                          *
                        </span>
                      </div>
                      <input
                        className={"attachment_panel_input"}
                        onChange={handleChange}
                        type={"text"}
                        placeholder={t(
                          "trainer.course.lesson.activity.crud.name"
                        )}
                        value={values.name}
                        name={"name"}
                        id={"name"}
                      />
                      <div>
                        {errors && errors.name ? (
                          <span className={"attachment_panel_input_error"}>
                            {errors.name}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className={"attachment_panel_input_holder_margin"}>
                      <div className={"attachment_panel_input_label"}>
                        <span>
                          {t("trainer.course.lesson.activity.description")}
                        </span>
                      </div>

                      <SunEditor
                        placeholder={t(
                          "trainer.course.lesson.activity.description"
                        )}
                        value={values.description}
                        defaultValue={values.description}
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: "description",
                              value: e,
                            },
                          });
                        }}
                        setOptions={{
                          height: 150,
                          rtl: i18n.language === "ar" ? true : false,
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "align",
                              "horizontalRule",
                              "list",
                              "lineHeight",
                              "codeView",
                            ],
                          ],
                        }}
                        setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                      />
                      <div>
                        {errors && errors.description ? (
                          <span className={"attachment_panel_input_error"}>
                            {errors.description}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className={"attachment_panel_input_holder_margin"}>
                      <div className={"attachment_panel_input_label"}>
                        <span>{t("trainer.course.lesson.activity.rpu")}</span>
                        <span className={"attachment_panel_asterisk_input"}>
                          *
                        </span>
                      </div>
                      <input
                        className={"attachment_panel_input"}
                        onChange={handleChange}
                        type={"number"}
                        min={0}
                        placeholder={t(
                          "trainer.course.lesson.activity.crud.rpu"
                        )}
                        value={values.rpu}
                        name={"rpu"}
                        id={"rpu"}
                      />
                      <div>
                        {errors && errors.rpu ? (
                          <span className={"attachment_panel_input_error"}>
                            {errors.rpu}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className={"attachment_panel_input_holder_margin"}>
                      <div className={"attachment_panel_input_label"}>
                        <span>
                          {t("trainer.course.lesson.activity.max_size")}
                        </span>
                        <span className={"attachment_panel_asterisk_input"}>
                          *
                        </span>
                      </div>
                      <input
                        className={"attachment_panel_input"}
                        onChange={handleChange}
                        type={"number"}
                        min={0}
                        placeholder={t(
                          "trainer.course.lesson.activity.crud.max_size"
                        )}
                        value={values.max_size}
                        name={"max_size"}
                        id={"max_size"}
                      />
                      <div>
                        {errors && errors.max_size ? (
                          <span className={"attachment_panel_input_error"}>
                            {errors.max_size}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className={"attachment_panel_input_holder_margin"}>
                      <div className={"attachment_panel_input_label"}>
                        <span>
                          {t("trainer.course.lesson.activity.require_approval")}
                        </span>
                        <span className={"attachment_panel_asterisk_input"}>
                          *
                        </span>
                      </div>
                      <Select
                        name="require_approval"
                        id="require_approval"
                        options={requireApprovalValues}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        onChange={(item) => {
                          setFieldValue("require_approval", item.value);
                        }}
                        defaultValue={
                          requireApprovalValues[
                            props.activity.require_approval == 1 ? 0 : 1
                          ].value
                        }
                        placeholder={t(
                          "trainer.course.lesson.activity.crud.require_approval"
                        )}
                      />
                      <div>
                        {errors && errors.require_approval ? (
                          <span className={"attachment_panel_input_error"}>
                            {errors.require_approval}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className={"attachment_panel_input_holder_margin"}>
                      <button
                        type={"button"}
                        className={"attachment_panel_submission_button"}
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        {isLocalSubmitting ? (
                          <div className="skeleton-box attachment_panel_submission_button_skeleton"></div>
                        ) : (
                          t("sendNow")
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default EditActivityToLessonModal;
