import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Field, FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import Input from "../../../Shared/Components/Input/Input";
import InputFile from "../../../Shared/Components/InputFile/InputFile";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import classes from "./courseForm.module.css";
import Select from "react-select";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { toServerTime } from "../../../../Services/Timezone";
import {
  MP4_TYPE,
  PNG_TYPE,
  acceptImages,
  acceptVideos,
  fromKbToMb,
  validateVideo,
} from "../../../../Services/Validator";
import { getListType } from "../../../../Services/api/TypeFile/TypeFileProvider";
import {
  editCourseVisibility,
  getCoursesInfo,
  getSpecialties,
  getSpecialtiesNew,
} from "../../../../Services/api/courses/courseProvider";

import { Lang } from "../../../../utils";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import SherdSelect from "../../../Shared/Components/Select/Select";
import SelectWithSearch from "../../../Shared/Components/SelectWithSearch/SelectWithSearch.jsx";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Tags from "../../../Shared/Components/Tags/Tags.jsx";

const CourseForm = ({
  submitHandler,
  type = "create",
  courseData,
  loading = false,
  isProgram,
  role,
  programCourses = false,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [allCourses, setAllCourses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [level, setLevel] = useState([]);
  const [groups, setGroups] = useState([]);
  const [startDateHours, setStartDateHours] = useState(null);
  const [startDateMinutes, setStartDateMinutes] = useState(null);
  const [endDateHours, setEndDateHours] = useState(null);
  const [endDateMinutes, setEndDateMinutes] = useState(null);
  const [startRegistrationHours, setStartRegistrationHours] = useState(null);
  const [startRegistrationMinutes, setStartRegistrationMinutes] =
    useState(null);
  const [endRegistrationHours, setEndRegistrationHours] = useState(null);
  const [endRegistrationMinutes, setEndRegistrationMinutes] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [listTypeFile, setListTypeFile] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    currentDate();

    getCoursesInfo()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setAllCourses(res.data.data.courses?.toReversed());
          setLevel(res.data.data.levels);
          setGroups(res.data.data.groups);
        }
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });

    // getSpecialties()
    //   .then((res) => {
    //     if (res.status && res.status === 200 && res.data.status) {
    //       setCategories(res.data.data.specialties.data);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err :>> ", err);
    //   });

    getSpecialtiesNew()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCategories(res.data.data.categories);
          // console.log(res.data.data.categories);
          if (courseData?.category_id) {
            setDepartments(
              res.data.data.categories.find(
                (category) => category.id === Number(courseData?.category_id)
              )?.departments
            );
          }
        }
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (courseData?.category_id && categories.length > 0) {
      setDepartments(
        categories.find(
          (category) => category.id === Number(courseData?.category_id)
        )?.departments
      );
    }
  }, [courseData?.category_id, categories]);

  const currentDate = (datePicker) => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    if (datePicker === "startDate") {
      setStartDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "endDate") {
      setEndDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "startRegisterDate") {
      setStartRegistrationHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartRegistrationMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "endRegisterDate") {
      setEndRegistrationHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndRegistrationMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else {
      setStartDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
      setEndDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
      setStartRegistrationHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartRegistrationMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
      setEndRegistrationHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndRegistrationMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    }
  };

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours === "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  const getListTypeFile = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          setListTypeFile(res?.data?.data);
          console.log(" listTypeFile ", listTypeFile);
          resolve();
        },
        (error) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("programs.failed_loading")}
            </span>
          );
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getListTypeFile();
  }, []);

  const getTypeFile = (searchType) => {
    return listTypeFile.filter((file) => file.type === searchType);
  };

  const getLocaleTime = (date) => {
    const offset = new Date().getTimezoneOffset();
    const localeTime = date.getTime() - offset * 60 * 1000;
    return new Date(localeTime);
  };

  const languageOptions = [
    { id: "ar", value: "ar", label: t("arabic") },
    { id: "en", value: "en", label: t("english") },
  ];

  const categoryOptions = categories.map((category) => ({
    id: category.id,
    value: category.id,
    label: i18n.language === Lang.AR ? category.name_ar : category.name_en,
  }));

  const departmentOptions = departments?.map((department) => ({
    id: department.id,
    value: department.id,
    label: i18n.language === Lang.AR ? department.name_ar : department.name_en,
  }));

  const courseOptions = allCourses.map((course) => ({
    id: course.id,
    value: course.id,
    label: course.name,
  }));

  const levelOptions = level.map((level) => ({
    id: level.value,
    value: level.value,
    label: level.text,
  }));

  const periods = [
    {
      id: 1,
      label: t("periods.years"),
      value: "year",
    },
    {
      id: 2,
      label: t("periods.months"),
      value: "month",
    },
    {
      id: 3,
      label: t("periods.weeks"),
      value: "week",
    },
    {
      id: 4,
      label: t("periods.days"),
      value: "day",
    },
  ];

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: `${role === "admin" ? "content_development" : "private_profile"}`,
      page: t(
        `sidebar.label.${
          role === "admin" ? "content_development" : "private_profile"
        }`
      ),
      pagePath:
        role === "admin" || localStorage.getItem("type") === "group-admin"
          ? "#"
          : "/private-profile",
    },
    {
      id: "courses",
      page: isProgram ? t("program") : t("courses"),
      pagePath:
        localStorage.getItem("type") == "group-admin"
          ? `/group-admin/${isProgram ? "program/list" : "courses"}`
          : localStorage.getItem("type") == "manager"
          ? `/manager/${isProgram ? "program/list" : "courses"}`
          : `/admin/${isProgram ? "program/list" : "courses"}`,
    },
    {
      id: "course",
      page:
        type === "create"
          ? isProgram
            ? t("trainer.program.add_program")
            : t("trainer.course.add_course")
          : courseData?.name,
      active: true,
    },
  ];
  const [group, setgroup] = useState(0);
  useEffect(() => {
    setgroup(Number(localStorage.getItem("group")));
    console.log(Number(localStorage.getItem("group")));
  }, []);

  return (
    <div className={classes["course"]}>
      <div className="container-fluid">
        <div className={classes["course__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {loading || isLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      height: "100vh",
                    }}
                  >
                    <SkeletonCardOverlay />
                  </div>
                </div>
              ) : (
                <MainBox>
                  <Formik
                    initialValues={{
                      name: courseData?.name || "",
                      description: courseData?.description || "",
                      brief_description: courseData?.brief_description || "",
                      image: courseData?.img
                        ? {
                            preview: courseData?.img,
                          }
                        : "",
                      // file: courseData?.file || "",
                      video: courseData?.video || "",
                      mode: courseData?.mode || "Paid",
                      price: courseData?.price || "",
                      number_hours: courseData?.number_hours || "",
                      participators: courseData?.participators || 1,
                      language: courseData?.language || "",
                      level: courseData?.level || "",
                      code: courseData?.code || "",
                      groups: [group],
                      // division: courseData?.division || "",
                      prerequisited_course_id:
                        courseData?.prerequisited_course_id || "",
                      category_id: courseData?.category_id || "",
                      section_id: courseData?.section_id || "",
                      tags: courseData?.tags || "",
                      start_date: courseData?.start_date
                        ? getLocaleTime(new Date(courseData?.start_date))
                        : setHours(
                            setMinutes(new Date(), startDateMinutes),
                            startDateHours
                          ),
                      end_date: courseData?.end_date
                        ? getLocaleTime(new Date(courseData?.end_date))
                        : setHours(
                            setMinutes(new Date(), endDateMinutes),
                            endDateHours
                          ),
                      start_register_date: courseData?.start_register_date
                        ? getLocaleTime(
                            new Date(courseData?.start_register_date)
                          )
                        : setHours(
                            setMinutes(new Date(), startRegistrationMinutes),
                            startRegistrationHours
                          ),
                      end_register_date: courseData?.end_register_date
                        ? getLocaleTime(new Date(courseData?.end_register_date))
                        : setHours(
                            setMinutes(new Date(), endRegistrationMinutes),
                            endRegistrationHours
                          ),
                      is_visible: courseData?.is_visible ? true : false,
                      certificate_after_course:
                        courseData?.certificate_after_course ? true : false,
                      self_paced: courseData?.self_paced ? true : false, ///////////////////////////////////////////////////////
                      duration: courseData?.duaration || "",
                      interval: courseData?.interval || "",
                      watch_success_percentage:
                        courseData?.watch_success_percentage ||
                        courseData?.watch_success_percentage === 0
                          ? courseData.watch_success_percentage
                          : "",
                      progress_success_percentage:
                        courseData?.progress_success_percentage ||
                        courseData?.progress_success_percentage === 0
                          ? courseData.progress_success_percentage
                          : "",

                      can_gift: courseData?.can_gift || "",
                      // is_scfhsv: courseData?.is_scfhsv ? true : false,
                      status: courseData?.status || "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      let data = { ...values };
                      data.is_scfhsv = data.is_scfhsv ? 1 : 0;
                      data.is_visible = data.is_visible ? 1 : 0;
                      data.certificate_after_course =
                        data.certificate_after_course ? "1" : "0";
                      data.self_paced = data.self_paced ? "1" : "0"; ////////////////////////////////////////////////////////
                      if (data.self_paced === "0") {
                        /////////////////////////////////////////////////////////
                        data.start_date = toServerTime(
                          formatDate(data.start_date) +
                            " " +
                            formatTime(data.start_date)
                        );
                        // if (!programCourses) {
                        //   data.groups = data.groups.map((group) => group.id);
                        // }
                        data.end_date = toServerTime(
                          formatDate(data.end_date) +
                            " " +
                            formatTime(data.end_date)
                        );
                        data.start_register_date = toServerTime(
                          formatDate(data.start_register_date) +
                            " " +
                            formatTime(data.start_register_date)
                        );
                        data.end_register_date = toServerTime(
                          formatDate(data.end_register_date) +
                            " " +
                            formatTime(data.end_register_date)
                        );
                        delete data.duration;
                        delete data.interval;
                      } else {
                        if (data.duration === "") {
                          delete data.duration;
                          delete data.interval;
                        }
                        delete data.start_date;
                        delete data.end_date;
                        delete data.start_register_date;
                        delete data.end_register_date;
                      }

                      if (data.image?.file) {
                        data.image = data.image.file;
                      } else if (data.image?.preview) {
                        delete data.image;
                      } else {
                        data.remove_image = 1;
                      }

                      isProgram && (data.is_program = 1);

                      if (programCourses) {
                        delete data.status;
                        delete data.mode;
                        delete data.start_register_date;
                        delete data.end_register_date;
                        delete data.price;
                        delete data.participators;
                        delete data.prerequisited_course_id;
                        delete data.certificate_after_course;
                        delete data.is_scfhsv;
                      }

                      const formData = new FormData();
                      for (let key in data) {
                        if (!data[key] && data[key] !== 0) continue;
                        if (Array.isArray(data[key])) {
                          data[key].forEach((item) => {
                            formData.append(`${key}[]`, item);
                          });
                        } else {
                          formData.append(key, data[key]);
                        }
                      }
                      if (
                        type === "canEdit" &&
                        values?.participators < courseData?.number_of_users
                      ) {
                        toast.error(
                          <>
                            <span
                              style={{
                                fontSize: 13,
                                fontWeight: "bold",
                                display: "block",
                                paddingBottom: "10px",
                              }}
                            >
                              {t("number_of_seat_massege")} -{" "}
                              {courseData?.number_of_users}
                            </span>
                          </>
                        );
                      } else {
                        await submitHandler(formData);
                      }
                    }}
                    validateOnChange={true}
                    validate={(values) => {
                      const errors = {};
                      // console.log("values :>> ", values);
                      console.log("errors :>> ", errors);

                      if (!values.name) {
                        errors.name = t("crud.errors.required");
                      }
                      if (!programCourses) {
                        if (
                          values.progress_success_percentage === "" &&
                          values.progress_success_percentage >= 0
                        ) {
                          errors.progress_success_percentage = t(
                            "crud.errors.required"
                          );
                        }
                        if (
                          values.watch_success_percentage === "" &&
                          values.watch_success_percentage >= 0
                        ) {
                          errors.watch_success_percentage = t(
                            "crud.errors.required"
                          );
                        }
                      }
                      if (!programCourses) {
                        if (values.mode === "Paid" && !values.price) {
                          errors.price = t("crud.errors.required");
                        }
                        if (!values.participators) {
                          errors.participators = t("crud.errors.required");
                        }
                        if (values.participators && values.participators <= 0) {
                          errors.participators = t(
                            "trainer.course.errors.min_participators"
                          );
                        }
                        if (!values.self_paced) {
                          if (!values.start_register_date) {
                            errors.start_register_date = t(
                              "crud.errors.required"
                            );
                          }
                          if (!values.end_register_date) {
                            errors.end_register_date = t(
                              "crud.errors.required"
                            );
                          }
                        }
                        if (values.self_paced && !values.duration) {
                          errors.duration = t("crud.errors.required");
                        }
                        if (values.self_paced && !values.interval) {
                          errors.interval = t("crud.errors.required");
                        }
                      }
                      if (!values.number_hours) {
                        errors.number_hours = t("crud.errors.required");
                      }
                      if (values.number_hours && values.number_hours <= 0) {
                        errors.number_hours = t(
                          "trainer.course.errors.min_number_hours"
                        );
                      }
                      if (
                        values.start_date &&
                        values.end_date &&
                        values.number_hours &&
                        !values.self_paced
                      ) {
                        const days = Math.ceil(
                          Math.abs(
                            new Date(values.start_date) -
                              new Date(values.end_date)
                          ) /
                            (1000 * 60 * 60 * 24)
                        );
                        if (values.number_hours > days * 24) {
                          errors.number_hours = t(
                            "trainer.course.errors.max_number_hours"
                          );
                        }
                      }
                      if (!values.code) {
                        errors.code = t("crud.errors.required");
                      }
                      if (!values.groups) {
                        errors.groups = t("crud.errors.required");
                      }
                      if (
                        values.code &&
                        !values.code.match(/^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$/)
                      ) {
                        errors.code = t("trainer.course.errors.code");
                      }
                      if (!values.category_id) {
                        errors.category_id = t("crud.errors.required");
                      }
                      if (!values.section_id) {
                        errors.section_id = t("crud.errors.required");
                      }
                      if (!values.self_paced) {
                        if (!values.start_date) {
                          errors.start_date = t("crud.errors.required");
                        }
                        if (!values.end_date) {
                          errors.end_date = t("crud.errors.required");
                        }
                      }

                      if (
                        values.video &&
                        !values.video.startsWith("https://") &&
                        !values.video.startsWith("http://")
                      ) {
                        errors.video = t("crud.errors.link");
                      }
                      if (
                        values.video &&
                        !values.video.includes("youtube.com") &&
                        !values.video.includes("youtu.be")
                      ) {
                        errors.video = t("crud.errors.youtube_link");
                      }
                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={classes["course__form"]}
                      >
                        {type === "cannotEdit" && (
                          <div className={classes["course__warning__note"]}>
                            {t("trainer.course.cannot_edit")}
                          </div>
                        )}
                        {type === "canEdit" && (
                          <div className={classes["course__warning__note"]}>
                            {t("trainer.course.can_edit")}
                          </div>
                        )}
                        {type === "preview" && (
                          <div className={classes["course__manage-content"]}>
                            <CustomButton
                              type="button"
                              value={t("trainer.course.manage_course_content")}
                              colors="#036c77"
                              action={() => {
                                history.push(
                                  `/admin/course/profile/${courseData?.id}`
                                );
                              }}
                            />
                          </div>
                        )}
                        <MainBoxHead
                          title={
                            type === "create"
                              ? isProgram
                                ? t("trainer.program.add_program")
                                : t("trainer.course.add_course")
                              : type === "edit"
                              ? isProgram
                                ? t("trainer.program.edit_program")
                                : t("trainer.course.edit_course")
                              : courseData?.name
                          }
                          actions={
                            !programCourses
                              ? [
                                  {
                                    id: "course-visibility",
                                    type: "switch",
                                    value: true,
                                    active: t("trainer.course.active"),
                                    inactive: t("trainer.course.inactive"),
                                    checked: values.is_visible,
                                    disabled: type === "preview",
                                    onChange: (event) => {
                                      editCourseVisibility(courseData?.id);
                                      setFieldValue(
                                        "is_visible",
                                        event.target.checked
                                      );
                                    },
                                  },
                                ]
                              : []
                          }
                        />

                        <div
                          className={`row ${classes["course__form__fields"]}`}
                        >
                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <Input
                              label={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.name`
                              )}
                              type="text"
                              name="name"
                              placeholder={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.name`
                              )}
                              onChange={handleChange}
                              error={errors.name}
                              value={values.name}
                              required
                              readonly={
                                type === "preview" || type === "cannotEdit"
                              }
                            />
                          </div>
                          {type !== "create" && !programCourses && (
                            <div
                              className={`${classes["course__form__field"]} col-12`}
                            >
                              <Checkbox
                                name="status"
                                label={t(
                                  `trainer.${
                                    isProgram ? "program" : "course"
                                  }.status`
                                )}
                                onChange={(event) => {
                                  setFieldValue(
                                    "status",
                                    event.target.checked
                                      ? "Published"
                                      : courseData?.status
                                  );
                                }}
                                checked={values.status === "Published"}
                                error={errors.status}
                                disabled={
                                  type === "preview" ||
                                  type === "cannotEdit" ||
                                  type === "canEdit"
                                }
                              />
                            </div>
                          )}

                          {!programCourses && (
                            <div
                              className={`${classes["course__form__field"]} col-12 col-md-6`}
                            >
                              <Checkbox
                                name="certificate_after_course"
                                label={t(
                                  `trainer.${
                                    isProgram ? "program" : "course"
                                  }.certificate_after_course`
                                )}
                                onChange={(event) => {
                                  setFieldValue(
                                    "certificate_after_course",
                                    event.target.checked
                                  );
                                }}
                                checked={values.certificate_after_course}
                                error={errors.certificate_after_course}
                                disabled={
                                  type === "preview" || type === "cannotEdit"
                                }
                              />
                            </div>
                          )}
                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <label
                              htmlFor="brief_description"
                              style={{ color: "#777777" }}
                            >
                              {t("trainer.course.brief_description")}
                            </label>
                            <textarea
                              id="brief_description"
                              name="brief_description"
                              style={{
                                border: "1px solid rgb(201, 200, 200)",
                                display: "block",
                                width: "100%",
                                borderRadius: "6px",
                                padding: "15px",
                              }}
                              maxLength={250}
                              value={values.brief_description}
                              onChange={handleChange}
                              placeholder={t(
                                "trainer.course.brief_description"
                              )}
                              defaultValue={values.brief_description}
                              disabled={
                                type === "preview" || type === "cannotEdit"
                              }
                            />
                          </div>
                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <label
                              htmlFor="description"
                              style={{ color: "#777777" }}
                            >
                              {t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.description`
                              )}
                            </label>

                            <SunEditor
                              placeholder={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.description`
                              )}
                              value={values.description}
                              defaultValue={values.description}
                              onChange={(e) => {
                                handleChange({
                                  target: { name: "description", value: e },
                                });
                              }}
                              setOptions={{
                                height: 150,
                                buttonList: [
                                  [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "align",
                                    "horizontalRule",
                                    "list",
                                    "lineHeight",
                                  ],
                                ],
                              }}
                              setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px; text-align: right; direction: rtl"
                              disable={
                                type === "preview" ||
                                type === "cannotEdit" ||
                                type === "canEdit"
                              }
                            />
                          </div>
                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <InputFile
                              id="image"
                              name="image"
                              type="gallery"
                              placeholder={""}
                              setFieldValue={setFieldValue}
                              accept={acceptImages()}
                              label={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.image`
                              )}
                              maxSize={`${t(
                                "trainer.course.upload_image"
                              )} ${fromKbToMb(
                                getTypeFile(PNG_TYPE)[0]?.max_size
                              )} ${t("megabyte")}`}
                              error={errors?.image}
                              value={values.image}
                              note={`${t(
                                "trainer.course.upload_image"
                              )} ${fromKbToMb(
                                getTypeFile(PNG_TYPE)[0]?.max_size
                              )} ${t("booth.megabyte")}`}
                              disabled={
                                type === "preview" ||
                                type === "cannotEdit" ||
                                type === "canEdit"
                              }
                            />
                          </div>
                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <Input
                              label={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.video`
                              )}
                              type="text"
                              name="video"
                              placeholder={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.video`
                              )}
                              onChange={handleChange}
                              error={errors.video}
                              value={values.video}
                              note={t("trainer.course.video_note")}
                              readonly={
                                type === "preview" ||
                                type === "cannotEdit" ||
                                type === "canEdit"
                              }
                            />
                          </div>
                          {!programCourses && !isProgram && (
                            <div
                              className={`${classes["course__form__field"]} col-12`}
                            >
                              <Checkbox
                                name="self_paced"
                                label={t(`open_course`)}
                                onChange={(event) => {
                                  setFieldValue(
                                    "self_paced",
                                    event.target.checked
                                  );
                                }}
                                checked={values.self_paced}
                                error={errors.self_paced}
                                disabled={type !== "create"}
                              />
                            </div>
                          )}
                          {!values.self_paced && (
                            <>
                              <div
                                className={`${classes["course__form__field"]} col-12 col-md-6`}
                              >
                                <MainDatePicker
                                  label={t(
                                    `trainer.${
                                      isProgram ? "program" : "course"
                                    }.start_date`
                                  )}
                                  name="start_date"
                                  error={errors.start_date}
                                  value={values.start_date}
                                  onChange={(date) => {
                                    setFieldValue("start_date", date);
                                    values.end_date < date &&
                                      setFieldValue("end_date", date);
                                    let CurrentDate = new Date();
                                    let CurrentDateFormate =
                                      CurrentDate.toISOString().split("T")[0];
                                    let DataPickerValue = date
                                      .toISOString()
                                      .split("T")[0];
                                    if (
                                      new Date(DataPickerValue).getTime() >
                                      new Date(CurrentDateFormate).getTime()
                                    ) {
                                      setStartDateHours(0);
                                      setStartDateMinutes(0);
                                    } else {
                                      currentDate("startDate");
                                    }
                                  }}
                                  minDate={new Date()}
                                  minTime={setHours(
                                    setMinutes(new Date(), startDateMinutes),
                                    startDateHours
                                  )}
                                  maxTime={setHours(
                                    setMinutes(new Date(), 30),
                                    23
                                  )}
                                  readOnly={type !== "create"}
                                  required
                                />
                              </div>
                              <div
                                className={`${classes["course__form__field"]} col-12 col-md-6`}
                              >
                                <MainDatePicker
                                  label={t(
                                    `trainer.${
                                      isProgram ? "program" : "course"
                                    }.end_date`
                                  )}
                                  name="end_date"
                                  error={errors.end_date}
                                  value={values.end_date}
                                  onChange={(date) => {
                                    setFieldValue("end_date", date);

                                    let CurrentDate = new Date();
                                    let CurrentDateFormate =
                                      CurrentDate.toISOString().split("T")[0];
                                    let DataPickerValue = date
                                      .toISOString()
                                      .split("T")[0];
                                    if (
                                      new Date(DataPickerValue).getTime() >
                                      new Date(CurrentDateFormate).getTime()
                                    ) {
                                      setStartDateHours(0);
                                      setStartDateMinutes(0);
                                    } else {
                                      currentDate("endDate");
                                    }
                                  }}
                                  minDate={new Date(values.start_date)}
                                  minTime={setHours(
                                    setMinutes(new Date(), startDateMinutes),
                                    startDateHours
                                  )}
                                  maxTime={setHours(
                                    setMinutes(new Date(), 30),
                                    23
                                  )}
                                  readOnly={
                                    type === "preview" || type === "cannotEdit"
                                  }
                                  required
                                />
                              </div>
                              {!programCourses && (
                                <div
                                  className={`${classes["course__form__field"]} col-12 col-md-6`}
                                >
                                  <MainDatePicker
                                    label={t(
                                      "trainer.course.start_register_date"
                                    )}
                                    name="start_register_date"
                                    error={errors.start_register_date}
                                    value={values.start_register_date}
                                    onChange={(date) => {
                                      setFieldValue(
                                        "start_register_date",
                                        date
                                      );
                                      values.end_register_date < date &&
                                        setFieldValue(
                                          "end_register_date",
                                          date
                                        );

                                      let CurrentDate = new Date();
                                      let CurrentDateFormate =
                                        CurrentDate.toISOString().split("T")[0];
                                      let DataPickerValue = date
                                        .toISOString()
                                        .split("T")[0];
                                      if (
                                        new Date(DataPickerValue).getTime() >
                                        new Date(CurrentDateFormate).getTime()
                                      ) {
                                        setStartDateHours(0);
                                        setStartDateMinutes(0);
                                      } else {
                                        currentDate("startRegisterDate");
                                      }
                                    }}
                                    minDate={new Date()}
                                    minTime={setHours(
                                      setMinutes(new Date(), startDateMinutes),
                                      startDateHours
                                    )}
                                    maxTime={setHours(
                                      setMinutes(new Date(), 30),
                                      23
                                    )}
                                    readOnly={
                                      type === "preview" ||
                                      type === "cannotEdit" ||
                                      type === "canEdit"
                                    }
                                    required
                                  />
                                </div>
                              )}
                              {!programCourses && (
                                <div
                                  className={`${classes["course__form__field"]} col-12 col-md-6`}
                                >
                                  <MainDatePicker
                                    label={t(
                                      "trainer.course.end_register_date"
                                    )}
                                    name="end_register_date"
                                    error={errors.end_register_date}
                                    value={values.end_register_date}
                                    onChange={(date) => {
                                      setFieldValue("end_register_date", date);
                                      values.end_date < date &&
                                        setFieldValue("end_date", date);

                                      let CurrentDate = new Date();
                                      let CurrentDateFormate =
                                        CurrentDate.toISOString().split("T")[0];
                                      let DataPickerValue = date
                                        .toISOString()
                                        .split("T")[0];
                                      if (
                                        new Date(DataPickerValue).getTime() >
                                        new Date(CurrentDateFormate).getTime()
                                      ) {
                                        setStartDateHours(0);
                                        setStartDateMinutes(0);
                                      } else {
                                        currentDate("endRegisterDate");
                                      }
                                    }}
                                    minDate={
                                      new Date(values.start_register_date)
                                    }
                                    minTime={setHours(
                                      setMinutes(new Date(), startDateMinutes),
                                      startDateHours
                                    )}
                                    maxTime={setHours(
                                      setMinutes(new Date(), 30),
                                      23
                                    )}
                                    maxDate={new Date(values.end_date)}
                                    readOnly={
                                      type === "preview" ||
                                      type === "cannotEdit"
                                    }
                                    required
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {values.self_paced && (
                            <>
                              <div
                                className={`${classes["course__form__field"]} col-12 col-md-6`}
                              >
                                <SherdSelect
                                  label={t(`period_type`)}
                                  name="interval"
                                  onchange={handleChange}
                                  options={periods}
                                  initialValue={t(`period_type`)}
                                  error={errors.interval}
                                  value={values.interval}
                                  disabled={
                                    type === "preview" ||
                                    type === "cannotEdit" ||
                                    courseData?.status === "Published"
                                  }
                                />
                              </div>
                              <div
                                className={`${classes["course__form__field"]} col-12 col-md-6`}
                              >
                                <Input
                                  label={t(`duration_open_course`)}
                                  type="number"
                                  name="duration"
                                  placeholder={t(`duration_open_course`)}
                                  onChange={handleChange}
                                  error={errors.duration}
                                  value={values.duration}
                                  note={t("durationNote")}
                                  readonly={
                                    type === "preview" ||
                                    type === "cannotEdit" ||
                                    courseData?.status === "Published"
                                  }
                                  required={values.duration}
                                />
                              </div>
                            </>
                          )}
                          {!programCourses && (
                            <div
                              className={`${classes["course__form__field"]} col-12`}
                            >
                              <Checkbox
                                name="mode"
                                label={t(
                                  `trainer.${
                                    isProgram ? "program" : "course"
                                  }.is_free`
                                )}
                                onChange={(event) => {
                                  setFieldValue(
                                    "mode",
                                    event.target.checked ? "Free" : "Paid"
                                  );
                                  setFieldValue(
                                    "price",
                                    event.target.checked ? "0.0" : ""
                                  );
                                }}
                                checked={values.mode === "Free"}
                                error={errors.mode}
                                disabled={
                                  (courseData?.start_date &&
                                    new Date(courseData?.start_date) <
                                      new Date()) ||
                                  type === "preview" ||
                                  type === "cannotEdit" ||
                                  type === "canEdit"
                                }
                              />
                            </div>
                          )}

                          {!programCourses && (
                            <div
                              className={`${classes["course__form__field"]} col-12 col-md-6`}
                            >
                              <Input
                                label={t(
                                  `trainer.${
                                    isProgram ? "program" : "course"
                                  }.price`
                                )}
                                type="number"
                                name="price"
                                placeholder={t(
                                  `trainer.${
                                    isProgram ? "program" : "course"
                                  }.price`
                                )}
                                onChange={handleChange}
                                error={errors.price}
                                value={values.price}
                                readonly={
                                  type !== "create" || values.mode === "Free"
                                }
                                required={values.mode === "Paid"}
                              />
                            </div>
                          )}

                          <div
                            className={`${classes["course__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.number_hours`
                              )}
                              type="number"
                              name="number_hours"
                              placeholder={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.number_hours`
                              )}
                              onChange={handleChange}
                              error={errors.number_hours}
                              value={values.number_hours}
                              required
                              readonly={
                                type === "preview" || type === "cannotEdit"
                              }
                            />
                          </div>
                          {!programCourses && (
                            <div
                              className={`${classes["course__form__field"]} col-12`}
                            >
                              <Input
                                label={t("trainer.course.participators")}
                                type="number"
                                name="participators"
                                placeholder={t("trainer.course.participators")}
                                onChange={handleChange}
                                error={errors.participators}
                                value={values.participators}
                                required
                                readonly={
                                  type === "preview" || type === "cannotEdit"
                                }
                              />
                            </div>
                          )}
                          <div
                            className={`${classes["course__form__field"]} col-12 col-md-6`}
                          >
                            <SherdSelect
                              label={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.language`
                              )}
                              name="language"
                              onchange={handleChange}
                              options={languageOptions}
                              initialValue={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.language`
                              )}
                              error={errors.language}
                              value={values.language}
                              disabled={
                                type === "preview" ||
                                type === "cannotEdit" ||
                                type === "canEdit"
                              }
                            />
                          </div>
                          <div
                            className={`${classes["course__form__field"]} col-12 col-md-6`}
                          >
                            <SherdSelect
                              label={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.level`
                              )}
                              name="level"
                              onchange={handleChange}
                              options={levelOptions}
                              initialValue={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.level`
                              )}
                              error={errors.level}
                              value={values.level}
                              disabled={
                                type === "preview" ||
                                type === "cannotEdit" ||
                                type === "canEdit"
                              }
                            />
                          </div>
                          {!programCourses && (
                            <>
                              <div
                                className={`${classes["course__form__field"]} col-12 col-md-6`}
                              >
                                <Input
                                  label={t(`watch_success_percentage`)}
                                  type="number"
                                  name="watch_success_percentage"
                                  placeholder={t(`watch_success_percentage`)}
                                  onChange={handleChange}
                                  error={errors.watch_success_percentage}
                                  value={values.watch_success_percentage}
                                  required
                                  readonly={
                                    type === "preview" ||
                                    type === "cannotEdit" ||
                                    type === "canEdit"
                                  }
                                  max={100}
                                  min={0}
                                  note={t("percentageNote")}
                                />
                              </div>
                              <div
                                className={`${classes["course__form__field"]} col-12 col-md-6`}
                              >
                                <Input
                                  label={t(`progress_success_percentage`)}
                                  type="number"
                                  name="progress_success_percentage"
                                  placeholder={t(`progress_success_percentage`)}
                                  onChange={handleChange}
                                  error={errors.progress_success_percentage}
                                  value={values.progress_success_percentage}
                                  required
                                  readonly={
                                    type === "preview" ||
                                    type === "cannotEdit" ||
                                    type === "canEdit"
                                  }
                                  max={100}
                                  min={0}
                                  note={t("percentageNote")}
                                />
                              </div>
                            </>
                          )}
                          <div
                            className={`${classes["course__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.code`
                              )}
                              type="text"
                              name="code"
                              placeholder={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.code`
                              )}
                              onChange={handleChange}
                              error={errors.code}
                              value={values.code}
                              required
                              readonly={type !== "create"}
                            />
                          </div>
                          {/* {!programCourses && (
                            <div
                              className={`${classes["course__form__field"]} col-12 col-md-6`}
                            >
                              <Input
                                label={t(
                                  `trainer.${
                                    isProgram ? "program" : "course"
                                  }.division`
                                )}
                                type="text"
                                name="division"
                                placeholder={t(
                                  `trainer.${
                                    isProgram ? "program" : "course"
                                  }.division`
                                )}
                                onChange={handleChange}
                                error={errors.division}
                                value={values.division}
                                readonly={
                                  type === "preview" ||
                                  type === "cannotEdit" ||
                                  type === "canEdit"
                                }
                              />
                            </div>
                          )} */}
                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <Tags
                              label={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.tags`
                              )}
                              name="tags"
                              placeholder={t(
                                `trainer.${
                                  isProgram ? "program" : "course"
                                }.tags`
                              )}
                              setFieldValue={setFieldValue}
                              value={values.tags}
                              error={errors.tags}
                              disabled={
                                type === "preview" ||
                                type === "cannotEdit" ||
                                type === "canEdit"
                              }
                            />
                          </div>
                          <div
                            className={`${classes["course__form__field"]} col-12 col-md-6`}
                          >
                            <SelectWithSearch
                              label={t("trainer.course.category")}
                              name="category_id"
                              onchange={(value) => {
                                setFieldValue("category_id", value.value);
                                setFieldValue("section_id", "");
                                setDepartments(
                                  categories
                                    .filter(
                                      (item) => item.id === Number(value.value)
                                    )[0]
                                    .departments.filter(
                                      (department) => department.status === true
                                    )
                                );
                              }}
                              options={categoryOptions}
                              value={{
                                id: values.category_id,
                                value: values.category_id,
                                label:
                                  i18n.language === Lang.AR
                                    ? categories.filter(
                                        (category) =>
                                          category.id ===
                                          Number(values.category_id)
                                      )[0]?.name_ar
                                    : categories.filter(
                                        (category) =>
                                          category.id ===
                                          Number(values.category_id)
                                      )[0]?.name_en,
                              }}
                              error={errors.category_id}
                              initialValue={t("trainer.course.category")}
                              disabled={
                                type === "preview" ||
                                type === "cannotEdit" ||
                                type === "canEdit"
                              }
                              required
                            />
                          </div>
                          <div
                            className={`${classes["course__form__field"]} col-12 col-md-6`}
                          >
                            <SelectWithSearch
                              label={t("trainer.course.section")}
                              name="section_id"
                              onchange={(value) => {
                                setFieldValue("section_id", value.value);
                              }}
                              options={departmentOptions}
                              value={{
                                id: values.section_id,
                                value: values.section_id,
                                label:
                                  i18n.language === Lang.AR
                                    ? departments?.filter(
                                        (department) =>
                                          department.id ===
                                          Number(values.section_id)
                                      )[0]?.name_ar
                                    : departments.filter(
                                        (department) =>
                                          department.id ===
                                          Number(values.section_id)
                                      )[0]?.name_en,
                              }}
                              error={errors.section_id}
                              initialValue={t("trainer.course.section")}
                              disabled={
                                type === "preview" ||
                                type === "cannotEdit" ||
                                type === "canEdit"
                              }
                              required
                            />
                          </div>

                          {!programCourses && (
                            <>
                              {/* <div
                                className={`${classes["course__form__field"]} col-12`}
                              >
                                <label htmlFor={"groups"}>
                                  {t("selectGroups")}
                                </label>
                                <Select
                                  isMulti
                                  name="groups"
                                  onChange={(item) => {
                                    setFieldValue("groups", item);
                                  }}
                                  options={groups.map((group) => {
                                    return {
                                      id: group.id,
                                      value: group.id,
                                      label: `${
                                        i18n.language === "ar"
                                          ? group.name_ar
                                          : group.name_en
                                      }`,
                                    };
                                  })}
                                  placeholder={t("selectGroups")}
                                  getOptionValue={(group) => group.id}
                                  defaultValue={values.groups.map((group) => ({
                                    id: group.id,
                                    value: group.id,
                                    label: group.name,
                                  }))}
                                  disabled={
                                    type === "preview" ||
                                    type === "cannotEdit" ||
                                    type === "canEdit"
                                  }
                                />
                                <p
                                  className={`form-input-error-space ${classes["select-field-error"]}`}
                                >
                                  {errors.groups && errors.groups}
                                </p>
                              </div> */}
                              <div
                                className={`${classes["course__form__field"]} col-12`}
                              >
                                <SherdSelect
                                  label={t("trainer.course.prerequisited")}
                                  name="prerequisited_course_id"
                                  onchange={handleChange}
                                  options={courseOptions}
                                  initialValue={t(
                                    "trainer.course.prerequisited"
                                  )}
                                  error={errors.prerequisited_course_id}
                                  value={values.prerequisited_course_id}
                                  disabled={
                                    type === "preview" ||
                                    type === "cannotEdit" ||
                                    type === "canEdit"
                                  }
                                />
                              </div>
                            </>
                          )}
                          {type !== "preview" && (
                            <div
                              className={`${classes["course__form__submit__container"]} col-12`}
                            >
                              <CustomButton
                                type="submit"
                                colors="#036c77"
                                value={
                                  type === "create"
                                    ? isProgram
                                      ? t("trainer.program.add_program_button")
                                      : t("trainer.course.add_course_button")
                                    : isProgram
                                    ? t("trainer.program.edit_program_button")
                                    : t("trainer.course.edit_course_button")
                                }
                                disable={isSubmitting || type === "cannotEdit"}
                                loading={isSubmitting}
                              />
                            </div>
                          )}
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseForm;
