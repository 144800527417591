import { Checkbox, Radio } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const SideBySideMatrix = (props) => {
  require("./style.css");

  const KeyCodes = {
    enter: 13,
  };
  const { t, i18n } = useTranslation();
  const delimiters = [KeyCodes.enter];
  // const [data, setData] = useState(props.matrixData);

  const data = {
    items: [
      { id: 1, name: "Odoo" },
      { id: 2, name: "Microsoft" },
      { id: 3, name: "SAAP" },
    ],
    options: [
      { id: 1, name: "UX" },
      { id: 2, name: "UI" },
      { id: 3, name: "API" },
    ],
    item_options: [
      { id: 1, name: "V.Good" },
      { id: 2, name: "Good" },
      { id: 3, name: "Bad" },
    ],
    answers: [
      { item_id: 1, option_id: 1, item_option_id: 2 },
      { item_id: 2, option_id: 3, item_option_id: 1 },
    ],
  };

  const demoItems = [{ name: i18n.language == "ar" ? "عنصر توضيحي" : "Demo Item" }]; //[{id: "1", name: "Odoo"}, {id: "2", name: "Microsoft"}, {id: "3", name:"SAAP"}];
  const demoOptions = [{ value: i18n.language == "ar" ? "خيار توضيحي" : "Demo Option" }]; //[{id: "1", name: "UX",}, {id: "2", name: "UI"}, {id: "3", name: "API"}];
  const demoItemOptions = [{ name: i18n.language == "ar" ? "خيار فرعي توضيحي" : "Demo Sub-option" }]; //[{id: "1", name: "V.Good"}, {id: "2", name: "Good"}, {id: "3", name: "Bad"}];
  const demoAnswers = [
    { item_id: 1, option_id: 1, item_option_id: 2 },
    { item_id: 2, option_id: 3, item_option_id: 1 },
  ];

  const [matrixData, setMatrixData] = useState(data);
  const [items, setItems] = useState(demoItems);
  const [options, setOptions] = useState(demoOptions);
  const [itemOptions, setItemOptions] = useState(demoItemOptions);
  const [answers, setAnswers] = useState([]);
  const [optionType, setOptionType] = useState([]);

  const [item, setItem] = useState("");
  const [option, setOption] = useState("");
  const [itemOption, setItemOption] = useState("");
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (props.mode == "edit") {
      save();
    }
  }, [items, options, itemOptions]);

  useEffect(() => {
    if (props.question) {
      setItems(props.question.items ?? []);
      setOptions(props.question.options ?? []);
      setItemOptions(props.question.item_options ?? []);
      const localAnswers = props.answers?.filter((answer) => answer.question.id == props.question.id);
      setAnswers(localAnswers || []);
    }
  }, [props.question]);

  const isSelected = (item, option, item_option) => {
    let answer = answers.filter(
      (answer) => answer.item.id == item.id && answer.option.id == option.id && answer.item_option.id == item_option.id
    );
    return answer.length ? true : false;
  };

  const setAnswer = (item, option, item_option) => {
    var found = false;
    var answerIndex = -1;
    var answerIndexs = [];
    var currentAnswers = [...answers];

    currentAnswers.map((answer, index) => {
      if (answer.item.id == item.id && answer.option.id == option.id) {
        // set related item options
        answerIndexs.push(index);
        // set the clicked item option
        if (answer.item_option.id == item_option.id) {
          found = true;
          answerIndex = index;
        }
      }
    });

    if (!found) {
      currentAnswers.push({
        item: { id: item.id, name: item.name },
        option: { id: option.id, value: option.value },
        item_option: { id: item_option.id, name: item_option.name },
        value: item_option.id,
      });
      // delete others if the selected type is radio

      answerIndexs.forEach((answerIndex) => {
        currentAnswers.splice(answerIndex, 1);
      });
    } else {
      currentAnswers.splice(answerIndex, 1);
    }
    setAnswers(currentAnswers);
    props.updateAnswers(props.question.id, currentAnswers);
  };

  const handleChange = (e) => {
    var action = e.target.name;
    var name = e.target.value;
    if (action == "item") {
      setItem(name);
    } else if (action == "option") {
      setOption(name);
    } else {
      setItemOption(name);
    }
  };

  // To insert new element
  const submitElement = (e) => {
    var action = e.target.name;
    if (e.keyCode === 13) {
      if (action == "item") {
        addItem();
      } else if (action == "option") {
        addOption();
      } else {
        addItemOption();
      }
      e.target.value = "";
    }
  };

  // To update current element
  const handleUpdate = (e, index) => {
    var action = e.target.name;
    var name = e.target.value;
    if (e.keyCode === 13) {
      if (name != "") {
        if (action == "item") {
          updateItem(index, name);
        } else if (action == "option") {
          updateOption(index, name);
        } else {
          updateItemOption(index, name);
        }
      } else {
        var re = window.confirm("هل أنت متأكد من رغبتك من حذف هذا العنصر؟");
        if (!re) {
          return;
        }
        if (action == "item") {
          removeItem(index);
        } else if (action == "option") {
          removeOption(index);
        } else {
          removeItemOption(index);
        }
      }
    }
  };

  const addItem = () => {
    setItems([...items, { name: item }]);
    setItem("");
  };

  const addOption = () => {
    setOptions([...options, { value: option }]);
    setOption("");
  };

  const addItemOption = () => {
    setItemOptions([...itemOptions, { name: itemOption }]);
    setItemOption("");
  };

  const updateItem = (index, name) => {
    var currentItems = [...items];
    currentItems.forEach((item, itemIndex) => {
      if (itemIndex == index) {
        item.name = name;
      }
    });
    setItems(currentItems);
  };

  const updateOption = (index, name) => {
    var currentOptions = [...options];
    currentOptions.forEach((option, optionIndex) => {
      if (optionIndex == index) {
        option.value = name;
      }
    });
    setOptions(currentOptions);
  };

  const updateItemOption = (index, name) => {
    var currentItemOptions = [...itemOptions];
    currentItemOptions.forEach((option, optionIndex) => {
      if (optionIndex == index) {
        option.name = name;
      }
    });
    setItemOptions(currentItemOptions);
  };

  const removeItem = (index) => {
    var filteredItems = items.filter((item, itemIndex) => itemIndex != index);
    setItems(filteredItems);
  };

  const removeOption = (index) => {
    var filteredOptions = options.filter((option, optionIndex) => optionIndex != index);
    setOptions(filteredOptions);
  };

  const removeItemOption = (index) => {
    var filteredItemOptions = itemOptions.filter((option, optionIndex) => optionIndex != index);
    setItemOptions(filteredItemOptions);
  };

  const save = () => {
    var data = {
      items,
      options,
      item_options: itemOptions,
    };
    props.handleSaveAction(data);
  };

  return (
    <>
      {/* Rendering */}
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            backgroundColor: "#ccc",
            flex: 1,
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="matrix-cell lg-view">
            {items.map((item, itemHeadingIndex) => (
              <div className="matrix-row" key={`item-heading-${itemHeadingIndex}`}>
                {props.mode == "edit" && !preview ? (
                  <input
                    defaultValue={item.name}
                    name="item"
                    onKeyUp={(e) => handleUpdate(e, itemHeadingIndex)}
                    style={{ margin: 5, padding: 5, width: "100%" }}
                    placeholder={t("survey.placeholders.item")}
                  />
                ) : (
                  <div className={"matrix-cell"}>{item.name}</div>
                )}
              </div>
            ))}
            {props.mode == "edit" && !preview ? (
              <div className="matrix-row">
                <div className="matrix-cell">
                  <input
                    name="item"
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => submitElement(e)}
                    placeholder={t("survey.placeholders.item")}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            ) : null}
          </div>
          {options.map((option, rowIndex) => (
            <>
              {/* Items */}
              <div className="matrix-cell md-view" key={`body-items-${option.id}-${rowIndex}`}>
                {items.map((item, itemHeadingIndex) => (
                  <div className="matrix-row" key={`item-heading-${itemHeadingIndex}`}>
                    <div className="matrix-cell">{item.name}</div>
                  </div>
                ))}
                {props.mode == "edit" && !preview ? (
                  <div className="matrix-row">
                    <div className="matrix-cell">
                      <input
                        name="item"
                        onChange={(e) => handleChange(e)}
                        onKeyDown={(e) => submitElement(e)}
                        placeholder={t("survey.placeholders.item")}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              {/* Options */}
              <div className="matrix-cell" key={`body-options-${option.id}-${rowIndex}`}>
                {props.mode == "edit" && !preview ? (
                  <input
                    name="option"
                    defaultValue={option.value}
                    onKeyUp={(e) => handleUpdate(e, rowIndex)}
                    placeholder={t("survey.placeholders.option")}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <div>{option.value}</div>
                )}
                <div>
                  {itemOptions.map((item_option, columnIndex) => (
                    <span className="matrix-cell" key={`span-1-item-${columnIndex}`}>
                      {props.mode == "edit" && !preview ? (
                        <input
                          name="itemOption"
                          defaultValue={item_option.name}
                          onKeyUp={(e) => handleUpdate(e, columnIndex)}
                          placeholder={t("survey.placeholders.item_option")}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <span>{item_option.name}</span>
                      )}
                    </span>
                  ))}
                </div>
                {items.map((item, itemHeadingIndex) => (
                  <div className="matrix-row" key={`item-body-${itemHeadingIndex}`}>
                    <fieldset>
                      {itemOptions.map((item_option, columnIndex) => (
                        <span className="matrix-cell">
                          {optionType == "checkbox" ? (
                            <Checkbox
                              // name={`item-${item.id}-${option.id}-${item_option.id}`}
                              checked={isSelected(item, option, item_option)}
                              onClick={() => setAnswer(item, option, item_option)}
                              disabled={!props.canAnswer}
                            />
                          ) : (
                            <Radio
                              // name={`item-${item.id}-${option.id}-${item_option.id}`}
                              checked={isSelected(item, option, item_option)}
                              onClick={() => setAnswer(item, option, item_option)}
                              disabled={!props.canAnswer}
                            />
                          )}
                        </span>
                      ))}
                    </fieldset>
                  </div>
                ))}
                {/* Add new Item Option & maintain the vertical space */}
                {props.mode == "edit" && !preview ? (
                  <div>
                    <input
                      className="bottom-new-item-option"
                      name="itemOption"
                      onChange={(e) => handleChange(e)}
                      onKeyDown={(e) => submitElement(e)}
                      placeholder={t("survey.placeholders.item_option")}
                      style={{ width: "100%" }}
                    />
                  </div>
                ) : null}
              </div>
            </>
          ))}
          {props.mode == "edit" && !preview ? (
            <>
              <div className="matrix-cell">
                <div className="matrix-row">
                  <input
                    name="option"
                    className="new-option"
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => submitElement(e)}
                    placeholder={t("survey.placeholders.option")}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
