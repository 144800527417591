import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateSystemUserStatus } from "../../../../Redux/Actions/systemUsers/systemUsersList";
import { useState } from "react";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import Groups from "../../../../assets/icons/students.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import permissionIcon from "../../../../assets/icons/permissions.svg";
import { Lang } from "../../../../utils";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./systemUsersList.module.css";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import ModelWithInput from "../../../Shared/Components/ModelWithInput/ModelWithInput";
import SelectGroupModel from "../../../Shared/Components/selectGroupModel/SelectGroupModel";
import { addGroupsToUser } from "../../../../Services/api/Roles/RolesProvider";
import { toast } from "react-toastify";

const SystemUsersListTable = ({
  sortAction,
  rolesEditHandle,
  currentPage,
  getSystemUsersListHandler,
  role,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { systemUsersList, systemUsersListLoading } = useSelector(
    (state) => state.systemUsersListReducer
  );

  const { t, i18n } = useTranslation();

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };
  const [showCertificateModel, setShowCertificateModel] = useState(false);
  const [certifacates, setCertifacates] = useState([]);
  const [selectGroupModel, setSelectGroupModel] = useState(false);
  const [userId, setUserId] = useState(null);

  const updateSystemUserStatusHandler = (id) => {
    dispatch(
      updateSystemUserStatus({
        id,
      })
    );
  };
  const openCertifacateModel = (certifacates) => {
    setCertifacates(certifacates);
    setShowCertificateModel(true);
  };

  const openSelectModel = (id) => {
    setSelectGroupModel(true);
    setUserId(id);
  };
  const assignGroupToUser = (groups) => {
    addGroupsToUser(userId, groups)
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
          setSelectGroupModel(false);
          getSystemUsersListHandler();
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.data.msg}
          </span>
        );
      });
  };

  const systemUsersActionsList = [
    {
      id: "view-details",
      icon: <img src={EyeIcon} alt="" />,
      action: (id) => {
        if (localStorage.getItem("type") == "group-admin") {
          window.open(`/group-admin/roles/${id}`, "_blank");
        } else if (localStorage.getItem("type") == "manager") {
          window.open(`/manager/roles/${id}`, "_blank");
        } else {
          window.open(`/admin/roles/${id}`, "_blank");
        }
      },
      tooltip: t("system_users.view_details"),
      color: "#5A80FF",
    },
    {
      id: "edit",
      icon: <img src={editIcon} alt="" />,
      action: (id) => {
        if (localStorage.getItem("type") == "group-admin") {
          window.open(`/group-admin/roles/edit/${id}`, "_blank");
        } else if (localStorage.getItem("type") == "manager") {
          window.open(`/manager/roles/edit/${id}`, "_blank");
        } else {
          window.open(`/admin/roles/edit/${id}`, "_blank");
        }
      },
      tooltip: t("system_users.edit"),
      color: "#13B5BD",
    },
    {
      id: "edit_role",
      icon: <img src={permissionIcon} alt="" />,
      action: (id) => {
        rolesEditHandle(id);
      },
      tooltip: t("block.label.edit"),

      color: "#f79f21",
    },
    ...(role === "groupAdmin" || role === "manager"
      ? []
      : [
          {
            id: "selectGroups",
            icon: <img src={Groups} alt="" width={20} height={20} />,
            action: (id) => {
              openSelectModel(id);
            },
            tooltip: t("selectGroups"),

            color: "#58b4b4",
          },
        ]),
    // {
    //   id: "delete",
    //   icon: <img src={DeleteIcon} alt="" />,
    //   action: (id) => {},
    //   tooltip: t("system_users.delete"),
    // },
  ];

  const displayGender = (gender) => {
    return Number(gender) === 1 ? t("male") : t("female");
  };

  const displayIsMedical = (isMedical) => {
    return Number(isMedical) === 1 ? t("yes") : t("no");
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (currentPage * 10 - 10)}</>
      ),
    },
    {
      id: "name",
      name: t("system_users.name"),
      key: i18n.language === Lang.AR ? "name_ar " : "name_en",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-name"]}>
          {rowData?.name || "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "email",
      name: t("system_users.email"),
      key: "email",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-email__wrapper"]}>
          <a
            className={classes["system-users-list__table-email"]}
            href={`mailto: ${rowData?.email}`}
          >
            {rowData?.email}
          </a>
        </div>
      ),
      sortable: false,
    },
    // {
    //   id: "alternative_email",
    //   name: t("system_users.alternative_email"),
    //   key: "alternative_email",
    //   cell: ({ rowData }) => (
    //     <div className={classes["system-users-list__table-email__wrapper"]}>
    //       <a
    //         className={classes["system-users-list__table-email"]}
    //         href={
    //           rowData?.alternative_email
    //             ? `mailto: ${rowData?.alternative_email}`
    //             : "#"
    //         }
    //       >
    //         {rowData?.alternative_email || "-"}
    //       </a>
    //     </div>
    //   ),
    //   sortable: false,
    // },
    // {
    //   id: "university_email",
    //   name: t("system_users.university_email"),
    //   key: "university_email",
    //   cell: ({ rowData }) => (
    //     <div className={classes["system-users-list__table-email__wrapper"]}>
    //       <a
    //         className={classes["system-users-list__table-email"]}
    //         href={
    //           rowData?.university_email
    //             ? `mailto: ${rowData?.university_email}`
    //             : "#"
    //         }
    //       >
    //         {rowData?.university_email || "-"}
    //       </a>
    //     </div>
    //   ),
    //   sortable: false,
    // },
    // {
    //   id: "register_type",
    //   name: t("system_users.register_type"),
    //   key: "register_type",
    //   cell: ({ rowData }) => (
    //     <div
    //       className={`${classes["system-users-list__table-register-type"]} ${
    //         classes[
    //           `system-users-list__table-register-type--${
    //             rowData?.type_login?.value || ""
    //           }`
    //         ]
    //       }`}
    //     >
    //       {rowData?.type_login?.text || "-"}
    //     </div>
    //   ),
    //   sortable: false,
    // },
    {
      id: "jobs",
      name: t("system_users.jobs"),
      key: "role",
      cell: ({ rowData }) => (
        <ul className={classes["system-users-list__table-jobs"]}>
          {!!rowData.roles &&
            rowData.roles.map((job) => (
              <li
                className={classes["system-users-list__table-jobs-item"]}
                key={job?.id}
              >
                {job?.label || "-"}
              </li>
            ))}
        </ul>
      ),
      sortable: false,
    },
    {
      id: "phone",
      name: t("system_users.phone"),
      key: "phone",
      cell: ({ rowData }) => (
        <div style={{ direction: "ltr" }}>{rowData?.phone || "-"}</div>
      ),
      sortable: false,
    },
    {
      id: "id_number",
      name: t("system_users.id_number"),
      key: "id_number",
      cell: ({ rowData }) => <>{rowData?.id_number || "-"}</>,
      sortable: false,
    },
    {
      id: "gender",
      name: t("system_users.gender"),
      key: "gender",
      cell: ({ rowData }) => (
        <>{rowData?.gender ? displayGender(rowData.gender) : "-"}</>
      ),
      sortable: false,
    },
    {
      id: "nationality",
      name: t("system_users.nationality"),
      key: "nationality",
      cell: ({ rowData }) => <>{rowData?.nationality?.name || "-"}</>,
      sortable: false,
    },
    {
      id: "country",
      name: t("system_users.country"),
      key: "country",
      cell: ({ rowData }) => <>{rowData?.country?.name || "-"}</>,
      sortable: false,
    },
    // {
    //   id: "is_medical",
    //   name: t("system_users.is_medical"),
    //   key: "is_medical",
    //   cell: ({ rowData }) => (
    //     <>{displayIsMedical(rowData?.is_medical) || "-"}</>
    //   ),
    //   sortable: false,
    // },
    {
      id: "major",
      name: t("system_users.major"),
      key: "major",
      cell: ({ rowData }) => (
        <>
          {i18n.language === Lang.AR
            ? rowData?.specialty?.name_ar
            : rowData?.specialty?.name_en || "-"}
        </>
      ),
      sortable: false,
    },
    {
      id: "department",
      name: t("system_users.department"),
      key: "department",
      cell: ({ rowData }) => <>{rowData?.department?.name || "-"}</>,
      sortable: false,
    },
    // {
    //   id: "scghs",
    //   name: t("system_users.scghs"),
    //   key: "scghs",
    //   cell: ({ rowData }) => <>{rowData?.scghs || "-"}</>,
    //   sortable: false,
    // },
    {
      id: "email_confirmation",
      name: t("system_users.email_confirmation"),
      key: "email_confirmation",
      cell: ({ rowData }) => (
        <>{rowData?.email_verified_at ? t("yes") : t("no")}</>
      ),
      sortable: false,
    },
    {
      id: "register_date",
      name: t("system_users.register_date"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: true,
    },
    {
      id: "certifications",
      name: t("private_profile.certificates"),
      key: "certifications",
      cell: ({ rowData }) => (
        <>
          <span
            style={{ color: "#025299", cursor: "pointer" }}
            onClick={() => openCertifacateModel(rowData.certifications)}
          >
            {t("general.attachment")}
          </span>
        </>
      ),
    },
    {
      id: "actions",
      name: t("system_users.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn actions={systemUsersActionsList} id={rowData.id} />
      ),
      sortable: false,
    },

    {
      id: "status",
      name: t("system_users.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.status == 1}
          id={rowData.id}
          onStatusChange={() => {
            updateSystemUserStatusHandler(rowData.id);
          }}
        />
      ),
      sortable: false,
    },
  ];
  
  return (
    <>
      <MainTable
        columns={columns}
        data={systemUsersList || []}
        isLoading={systemUsersListLoading}
        sortAction={sortAction}
      />
      {showCertificateModel && (
        <ModelWithInput
          openModal={showCertificateModel}
          setOpenModal={setShowCertificateModel}
          modalTitle={t("private_profile.certificates")}
          certifacates={certifacates}
          // buttonText={t("accept")}
          type={"certificate"}
          // handleSubmit={ConfirmRejectHandler}
          // inputLabel={t("financial.payments_request.rejected_reason")}
        />
      )}
      {selectGroupModel && (
        <SelectGroupModel
          openModal={selectGroupModel}
          setOpenModal={setSelectGroupModel}
          modalTitle={t("selectGroups")}
          buttonText={t("accept")}
          userId={userId}
          handleSubmit={assignGroupToUser}
        />
      )}
    </>
  );
};

export default SystemUsersListTable;
